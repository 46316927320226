@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
	outline: none !important;
}

.data-hidden {
	overflow: hidden;
}

ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

body {
	margin: 0;
	font-family: "Inter";
	overflow-x: hidden;
	user-select: none;
}

a {
	text-decoration: none;
	display: inline-block;
}

a:hover {
	text-decoration: none;
	@include prefix(transition, all 300ms ease-in);
}

img {
	width: 100%;
	height: auto;
	display: block;
}

/* Remove inner shadow from inputs on mobile iOS */
textarea,
input {
	-webkit-appearance: none;
	@include prefix(border-radius, 0);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
}

h1 {
	@include fonts("Work Sans", $color8, 32px, 600, 40px, 0);
}
h2 {
	@include fonts("Inter", $color8, 28px, 600, 36px, 0);
}
h3 {
	@include fonts("Work Sans", $color8, 32px, 600, 36px, 0);
}
h4 {
	@include fonts("Work Sans", $color1, 18px, 600, 24px, 0);
}
p {
	@include fonts("Inter", $color8, 16px, Normal, 28px, 0);
}
.price-text {
	@include fonts("Quicksand", $color8, 20px, Bold, 26px, 0);
}

select {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	height: 46px;
	width: 100%;
	max-width: 600px;
	border-bottom: 1px solid $color8 !important;
	border: 0;
	box-sizing: border-box;
	padding: 0 32px 0 6px;
	outline: none !important;
	box-shadow: none !important;
	@include prefix(border-radius, 0px);
	background: transparent;
	background-image: url(../Assets/img/chevron-down.svg);
	background-position: calc(100% - 9px), calc(100% - 9px);
	background-size: auto;
	background-repeat: no-repeat;
}

span{
	display: block;
}

.f1632 {
	@include fonts("Inter", $color8, 16px, 500, 32px, 0);
}

.f4852 {
	@include fonts("Inter", $color8, 48px, 600, 52px, 0);
}

.f2436 {
	@include fonts("Inter", $color8, 24px, 600, 36px, 0);
}

.f1422 {
	@include fonts("Inter", $color8, 14px, normal, 22px, 0);
}

.f1424 {
	@include fonts("Inter", $color8, 14px, bold, 24px, 0);
}

.f4048 {
	@include fonts("Work Sans", $color8, 40px, normal, 48px, 0.01em);
}

.f1624 {
	@include fonts("Work Sans", $color8, 16px, 500, 24px, 0);
}

.f1432 {
	@include fonts("Inter", $color8, 14px, 500, 32px, 0);
}

.f1826 {
	@include fonts("Inter", $color8, 18px, 600, 26px, 0);
}

.f1824 {
	@include fonts("Work Sans", $color8, 18px, Normal, 24px, 0);
}

.f1214 {
	@include fonts("Work Sans", $color8, 12px, 600, 14px, 0);
}

.f1828 {
	@include fonts("Inter", $color8, 18px, 600, 28px, 0);
}

.f2036 {
	@include fonts("Work Sans", $color8, 20px, 600, 36px, 0);
}

.f1626 {
	@include fonts("Work Sans", $color8, 16px, 600, 26px, 0);
}

@include mobile {
	h1 {
		line-height: 36px;
	}
	h2 {
		font-size: 24px;
		line-height: 32px;
	}
	h3 {
		font-size: 24px;
		line-height: 26px;
	}
}

.show-768 {
	@include ultra-mobile {
		display: none;
	}
}
.hide-767 {
	@include ultra-mobile-above {
		display: none;
	}
}
.show-992 {
	@include tablet {
		display: none;
	}
}
.hide-991 {
	@include tablet-above {
		display: none;
	}
}

.show-576 {
	@include mobile-above {
		display: none;
	}
}

.hide-575 {
	@include mobile {
		display: none;
	}
}

body {
	* {
		&::-webkit-scrollbar {
			width: 3px;
			background: $color4;
		}
		&::-webkit-scrollbar-thumb {
			background: $color13;
		}
		&::-webkit-scrollbar-corner {
			background: $color13;
		}
	}
}


.container{
	@include mobile{
		padding-left: 28px;
		padding-right: 28px;
	}
}


.cms-page{
  padding-bottom: 42px;
	background-color: $color2;
  @include mobile{
    padding-bottom: 10px;
  }
  .cus-breadcrumb{
    margin-bottom: 16px;
  }
  h1{
    @include fonts("Inter", $black, 32px, 600, 36px, 0);
    margin-bottom: 32px;
    @include mobile{
      font-size: 24px;
      line-height: 26px;
    }
  } 
  h4{
    color: $color1;
    margin-bottom: 8px;
  }
  p{
    margin-bottom: 48px;
  }
}


.form-section{
  padding-top: 64px;
  padding-bottom: 90px;
	.max-464{
		max-width: 464px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		flex-direction: column;
	}
  @include large-tablet{
    min-height: calc(100vh - 680px);
  }
  @include mobile{
    padding-top: 45px;
    padding-bottom: 45px;
  }
  h1{
    margin-bottom: 52px;
		color: $black;
    @include mobile{
      margin-bottom: 34px;
			font-size: 24px;
			line-height: 26px;
    }
  }
  .input-groups{
    margin-bottom: 16px;
    &:last-child{
      margin-bottom: 0;
    }
  }
	.fill-btn, .border-btn{
		width: 100%
	}
}

.pages-link{
  @include fonts("Inter", $color8, 14px, normal, 20px, 0);
	text-decoration: underline;
	@include prefix(transition, all 300ms ease-in);
	&:hover{
		color: black;
	}
}

.go-back-btn{
	display: inline-block;
	position: relative;
	padding-left: 21px;
	@include prefix(transition, all 300ms ease-in);
	&::before{
		position: absolute;
		content: '';
		top: 50%;
		left: 0;
		height: 8px;
		width: 5px;
		@include prefix(transform, translateY(-50%));
		@include background("../Assets/img/primary-left.svg");
	}
	span{
		display: block;
		@include fonts("Work Sans", $color1, 14px, 600, 16px, 0.02em);
		@include prefix(transition, all 300ms ease-in);
    text-transform: uppercase;
	}
	&:hover{
		span{
			text-decoration: underline;
		}
	}
}


.owl-theme .owl-dots{
	margin-top: 0;
	@include flex-center-center;
	.owl-dot{
		margin: 0 6px;
		@include hw(7px);
		span{
			display: none;
		}
		&:nth-child(5n - 4){
			@include background("../Assets/img/Dot-1.svg");
			&.active{
				@include background("../Assets/img/Dot-fill-1.svg");
			}
		}
		&:nth-child(5n - 3){
			@include background("../Assets/img/Dot-2.svg");
			&.active{
				@include background("../Assets/img/Dot-fill-2.svg");
			}
		}
		&:nth-child(5n - 2){
			@include background("../Assets/img/Dot-3.svg");
			&.active{
				@include background("../Assets/img/Dot-fill-3.svg");
			}
		}
		&:nth-child(5n - 1){
			@include background("../Assets/img/Dot-4.svg");
			&.active{
				@include background("../Assets/img/Dot-fill-4.svg");
			}
		}
		&:nth-child(5n){
			@include background("../Assets/img/Dot-5.svg");
			&.active{
				@include background("../Assets/img/Dot-fill-5.svg");
			}
		}
		// &:nth-child(6){
		// 	@include background("../Assets/img/Dot-1.svg");
		// 	&.active{
		// 		@include background("../Assets/img/Dot-fill-1.svg");
		// 	}
		// }
		// &:nth-child(7){
		// 	@include background("../Assets/img/Dot-2.svg");
		// 	&.active{
		// 		@include background("../Assets/img/Dot-fill-2.svg");
		// 	}
		// }
		// &:nth-child(8){
		// 	@include background("../Assets/img/Dot-3.svg");
		// 	&.active{
		// 		@include background("../Assets/img/Dot-fill-3.svg");
		// 	}
		// }
		// &:nth-child(9){
		// 	@include background("../Assets/img/Dot-4.svg");
		// 	&.active{
		// 		@include background("../Assets/img/Dot-fill-4.svg");
		// 	}
		// }
		// &:nth-child(10){
		// 	@include background("../Assets/img/Dot-5.svg");
		// 	&.active{
		// 		@include background("../Assets/img/Dot-fill-5.svg");
		// 	}
		// }

	}
}


.tns-outer .tns-nav{
	margin-top: 0;
	z-index: 2;
	position: absolute;
	bottom: 0;
	left: 50%;
	@include prefix(transform, translateX(-50%));
	@include flex-center-center;
	button{
		margin: 0 6px;
		@include hw(7px);
		padding: 0;
		border: 0;
		&:nth-child(5n - 4){
			@include background("../Assets/img/Dot-1.svg");
			&.tns-nav-active{
				@include background("../Assets/img/Dot-fill-1.svg");
			}
		}
		&:nth-child(5n - 3){
			@include background("../Assets/img/Dot-2.svg");
			&.tns-nav-active{
				@include background("../Assets/img/Dot-fill-2.svg");
			}
		}
		&:nth-child(5n - 2){
			@include background("../Assets/img/Dot-3.svg");
			&.tns-nav-active{
				@include background("../Assets/img/Dot-fill-3.svg");
			}
		}
		&:nth-child(5n - 1){
			@include background("../Assets/img/Dot-4.svg");
			&.tns-nav-active{
				@include background("../Assets/img/Dot-fill-4.svg");
			}
		}
		&:nth-child(5n){
			@include background("../Assets/img/Dot-5.svg");
			&.tns-nav-active{
				@include background("../Assets/img/Dot-fill-5.svg");
			}
		}
	}
}

.web-view{
	@include ultra-mobile{
		display: none;
	}
}
.mobile-view{
	@include ultra-mobile-above{
		display: none;
	}
}