.subscription-wrap {
    .page-content {
		margin-top: 32px;
        .sub-info {
            margin-bottom: 24px;
            @include mobile {
                margin-bottom: 16px;
            }
            .sub-timeperiod {
                margin-top: 8px;
                @include mobile {
                    margin-top: 4px;
                    @include flex-between-center;
                }
                .sub-dates {
                    @include fonts("Inter", $color13, 14px, 400, 28px, 0);
                    @include mobile-above {
                        padding-right: 60px;
                    }
                }
                .sub-due {
                    @include fonts("Inter", $black, 14px, 400, 28px, 0);
                }
            }
        }
        .sub-progress {
            .lower-bar {
                position: relative;
                height: 6px;
                background: $color4;
                @include prefix(border-radius, 50px);
                .upper-bar {
                    height: 6px;
                    position: absolute;
                    background: $color5;
                    @include prefix(border-radius, 50px);
                }
            }
        }
        .sub-additional {
            margin-top: 60px;
            p {
                @include fonts("Inter", $color8, 16px, 400, 28px, 0);
            }
        }
        .action-bar {
            margin-top: 32px;
            .MuiButton-contained.fill-btn {
                width: 304px;
                @include mobile {
                    width: 100%;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }
}