.mobile-account-wrapper{
  background-color: $color2;
  min-height: 100vh;
  padding-bottom: 104px;
  @include tablet-above{
    display: none;
  }
  .container{
    @include mobile{
      padding-left: 28px;
		  padding-right: 28px;
    }
  }
  .cus-breadcrumb{
    padding: 16px 0;
    margin-bottom: 16px;
  }
  .page-title{
    h5{
	    @include fonts("Work Sans", $black, 24px, 600, 26px, 0);
    }
  }
}