.forgot-success {
  text-align: center;
  padding-top: 90px;
  padding-bottom: 90px;
  @include mobile {
    padding-top: 45px;
    padding-bottom: 57px;
  }
  .FS-wrapper{
    max-width: 352px;
    margin-left: auto;
    margin-right: auto;
    img{
      width: 300px;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;
      @include mobile{
        width: 100%;
      }
    }
    h1{
      @include fonts("Work Sans", $black, 34px, 600, 32px, 0);
      margin-bottom: 24px;
      @include mobile{
        margin-bottom: 16px
      }
    }
    p{
      @include fonts("Inter", $black, 16px, 400, 24px, 0);
      margin-bottom: 32px;
    }
    .fill-btn{
      width: 352px;
      margin-left: auto;
      margin-right: auto;
      @include mobile{
        width: 100%;
      }
    }
  }
}
