.checkout-wrap {
  .container {
    @include ultra-mobile {
      padding: 0 28px;
    }
  }
  .ck-main {
    max-width: 976px;
    margin: 64px auto 120px auto;
    @include flex-between-start;
    @include prefix(flex-direction, row);
    .ck-process {
      width: 600px;
      @include tablet {
        width: 400px;
      }
      .step-title {
        margin-bottom: 12px;
        @include fonts("Work Sans", $color1, 20px, 600, 26px, 0);
        padding-left: 28px;
      }
      .next-step {
        cursor: pointer;
        background-color: $color2;
        margin-bottom: 16px;
        padding: 20px 28px 20px 28px;
        @include fonts("Work Sans", $color1, 20px, 600, 26px, 0);
        width: 100%;
      }
      .prev-step {
        cursor: pointer;
        background-color: $color2;
        margin-bottom: 32px;
        padding: 20px 28px 20px 28px;
        @include flex-start-center;
        @include fonts("Work Sans", $color8, 20px, 600, 26px, 0);
        span {
          margin-left: 4px;
          @include fonts("Work Sans", $color8, 14px, 400, 26px, 0);
        }
        @include tablet {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .ck-summary {
      width: calc(100% - 616px);
      @include tablet {
        width: calc(100% - 416px);
      }
    }
  }
  &.mobile {
    .checkout-wrapper {
      padding-bottom: 148px;
      // max-height: calc(100vh - 120px);
      // overflow-y: auto;
      .step-title {
        @include fonts("Work Sans", $black, 24px, 600, 26px, 0);
        margin-top: 32px;
      }
      .address-steps, .payment-steps {
        padding: 16px 0 0 0;
        border: 0;
      }
      .order-confirmwrap {
        padding-bottom: 66px;
      }
      .cart-items {
        background-color: $white;
        padding-top: 32px;
        padding-left: 0;
        padding-right: 0;
        p.f2036, a.delete, .shipping-section, .total-section, .action-bar {
          display: none;
        }
        .bag-content {
          .cart-box {
            .item {
              margin-bottom: 0;
              border-bottom: 0;
              &:not(:last-child) {
                padding-bottom: 40px;
              }
              &:last-child {
                padding-bottom: 0;
              }
              .cart-data {
                padding-left: 16px;
              }
              .atrributes {
                .with-incdec {
                  .inc-dec-box {
                    width: 60px; 
                  }
                  .select-group {
                    width: 100%;
                    max-width: calc(100% - 68px);
                    select {
                      @include fonts("Inter", $color1, 12px, 400, 24px, 0);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .checkout-footer {
    background-color: rgba(255, 185, 142, 0.3);
    padding: 64px 0;
    .container{
      @include above-1400{
        max-width: 1184px;
        margin-left: auto;
        margin-right: auto;
        padding: 0
      }
    }
    .ckfooter-points {
      @include flex-between-center;
      .point-wrap {
        img {
          width: 52px;
          margin: 0 auto;
        }
        .pt-title {
          padding-top: 12px;
          @include fonts("Work Sans", $black, 14px, 600, 24px, 0);
          text-align: center;
          text-transform: uppercase;
        }
        .pt-desc {
          @include fonts("Inter", $black, 14px, 400, 28px, 0);
          text-align: center;
        }
      }
    }
  }
}
