.faqs {
  padding-bottom: 90px;
  background-color: $color2;
  .container{
    max-width: 824px;
  }
  @include mobile {
    padding-bottom: 45px;
  }
  .cus-breadcrumb {
    margin-bottom: 16px;
  }
  h1 {
    @include fonts("Inter", $black, 32px, 600, 36px, 0);
    margin-bottom: 32px;
    @include mobile {
      font-size: 24px;
      line-height: 26px;
    }
  }
  .accordion {
    .accordion-item {
      border: 0;
      border-radius: 0;
      background-color: transparent;
      border-bottom: 1px solid $color4;
      &:first-of-type {
        .accordion-button {
          padding-top: 0 !important;
        }
      }
      @include tablet {
        background-color: transparent;
      }
      .accordion-button { 
        background-color: transparent;
        @include prefix(justify-content, space-between);
        padding: 16px 0 16px 0;
        h6{
	        @include fonts("Inter", $color8, 18px, 500, 28px, 0);
          padding-right: 12px;
        }
        &::after {
          background-image: url(../../Assets/img/accr-plus.svg);
          background-position: center;
          background-size: 12px;
          margin-left: auto;
        }
        &:not(.collapsed) {
          font-weight: 600;
          background-color: transparent;
          box-shadow: none;
          &::after {
            background-image: url(../../Assets/img/accr-minus.svg);
            transform: rotate(0deg);
          }
        }
        &:focus {
          background-color: transparent;
          box-shadow: none;
        }
        &:hover {
          z-index: 0;
        }
      }
      .accordion-body {
        padding: 0 0 16px 0;
        p{
          margin-bottom: 24px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
