.login-steps {
    padding: 40px 28px 48px 28px;
    border: 1px solid $color4;
    margin-bottom: 16px;
    @include tablet {
        padding: 32px 20px 24px 20px;
    }
    .nav-tabs {
        .nav-item {
            border: 0;
            width: calc(100% / 3);
            .nav-link {
                @include fonts("Work Sans", $color8, 16px, 400, 26px, 0);
                opacity: 0.6;
                width: 100%;
                @include prefix(transition, none);
                border-bottom: 1px solid $color4;
                padding-left: 0;
                padding-right: 0;
                &.active {
                    border: 0;
                    @include fonts("Work Sans", $color1, 16px, 600, 26px, 0);
                    opacity: 1;
                    position: relative;
                    &:after {
                        content: ' ';
                        background-color: $color1;
                        height: 4px;
                        display: block;
                        position: absolute;
                        width: 100%;
                        left: 0;
                        bottom: -3.5px;
                        z-index: 1;
                    }
                }
                &:hover {
                    border-color: transparent;
                }
            }
        }
    }
}