.club-wrap {
  .grey-bg-mobile {
    @include mobile {
      background: #f4f2f1;
    }
  }
  .joinclub-wrap {
    padding: 84px 0 104px 0;
    margin: 0 auto;
    max-width: 600px;
    h2 {
      margin-bottom: 72px;
      @include mobile {
        margin-bottom: 36px;
        text-align: left;
      }
    }
    .for-img {
      margin: 0 auto;
      @include hw(148px);
    }
    .for-desc {
      margin-top: 32px;
      @include mobile {
        margin-top: 28px;
      }
      h4 {
        text-transform: uppercase;
        color: $color1;
        margin-bottom: 8px;
      }
      @include mobile {
        text-align: center;
      }
    }
    @include mobile {
      padding: 64px 0 62px 0;
      .container {
        padding-left: 28px;
        padding-right: 28px;
      }
      .point-in {
        &:not(:last-child) {
          margin-bottom: 48px;
        }
      }
    }
    @include desktop-above {
      .row {
        margin-left: -30px;
        margin-right: -30px;
        > * {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
  }
  .clubpart-wrap {
    .club-inwrap {
      .club-right {
        background-color: $color2;
        @include mobile {
          display: none;
        }
        .cr-in {
          max-width: 496px;
          .club-head {
            @include tablet-above {
              p {
                &:not(:last-child) {
                  margin-bottom: 20px;
                }
              }
            }
          }
          .subscriptionError {
            color: red;
          }
          .cpoint-details {
            width: 100%;
            @include flex-between-center;
            @include mobile {
              flex-direction: row;
            }
            .point {
              padding: 24px 0;
              width: 50%;
              max-width: calc(50% - 8px);
              background: #ffffff;
              @include prefix(border-radius, 4px);
              text-align: center;
              @include mobile {
                flex-direction: column;
                max-width: calc(50% - 4px);
              }
              .time {
                margin-bottom: 8px;
                @include mobile {
                  min-height: 50px;
                  display: flex;
                  align-items: center;
                }
                span {
                  @include fonts("Work Sans", $color8, 18px, 400, 24px, 0);
                }
              }
              .amount {
                .price-text {
                  font-size: 34px;
                  line-height: 37.65px;
                  color: $color1;
                  @include mobile {
                    font-size: 32px;
                  }
                  // &:first-letter {
                  //   @include fonts("Work Sans", $color1, 32px, 500, 37.65px, 0);
                  // }
                }
              }
              border: 1.5px solid transparent;
              &.active {
                border: 1.5px solid $color5;
                position: relative;
                &:before {
                  content: " ";
                  @include hw(12px);
                  background-color: $color5;
                  border-radius: 50%;
                  position: absolute;
                  top: 8px;
                  left: 8px;
                }
              }
              &:hover {
                border: 1.5px solid $color5;
                cursor: pointer;
              }
            }
          }
          .action-bar {
            .MuiButton-contained.fill-btn {
              max-width: 304px;
              width: 100%;
              @include mobile {
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .faq-wrap {
    padding: 104px 0;
    margin: 0 auto;
    max-width: 600px;
    @include ultra-mobile {
      padding: 64px 0px;
    }
    .container {
      padding: 0;
    }
    h2 {
      margin-bottom: 48px;
      @include ultra-mobile {
        margin-bottom: 40px;
      }
    }
    .accordion-wrap {
      h2 {
        margin-bottom: 0;
      }
      .accordion-item {
        border: 0;
        border-bottom: 1px solid $color4;
        &:first-of-type {
          .accordion-button {
            padding-top: 0 !important;
          }
        }
        .accordion-button {
          @include fonts("Inter", $color8, 18px, 500, 28px, 0);
          box-shadow: none;
          background-color: transparent;
          padding: 16px 6px 16px 2px;
          &::after {
            background-image: url(../../Assets/img/accr-plus.svg);
            background-position: center;
            background-size: 12px;
          }
          &:not(.collapsed) {
            font-weight: 600;
            // padding: 16px 6px 4px 0;
            &::after {
              background-image: url(../../Assets/img/accr-minus.svg);
              transform: rotate(0deg);
            }
          }
        }
        .accordion-body {
          padding: 0 0 16px 0;
        }
      }
    }
  }
}

.faq-club-wrapper {
  background: #f4f2f1;
  @include mobile-above{
    background: #FFF;
  }
  .accordion-item {
    // background: #f4f2f1;
    background: transparent;
  }
}

.club-cms-section {
  .info-wrap {
    padding: 84px 0 104px 0;
    margin: 0 auto;
    max-width: 600px;
    text-align: left;
    @include ultra-mobile {
      padding: 82px 0 64px 0;
      .container {
        padding: 0 28px;
      }
    }
    h2 {
      @include fonts("Work Sans", #000000, 32px, 600, 36px, 0);
      margin-bottom: 32px;
      @include ultra-mobile {
        margin-bottom: 24px;
      }
    }
    h3 {
      @include fonts("Inter", #000000, 24px, 400, 32px, 0);
      margin-bottom: 20px;
      b,
      strong {
        font-weight: 600;
      }
    }
    img {
      margin-top: 40px;
      margin-bottom: 40px;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
    p {
      @include fonts("Inter", #000000, 16px, 400, 28px, 0);
      a {
        text-decoration: underline;
        font-weight: 600;
      }
      // &:not(:last-child) {
      //   margin-bottom: 24px;
      //   @include ultra-mobile {
      //     margin-bottom: 16px;
      //   }
      // }
    }
    & > *:last-child {
      margin-bottom: 0;
    }
  }
}

.premium-section {
  padding-top: 180px;
  padding-bottom: 130px;
  background: url(../../Assets/img/premium-shape.svg);
  background-repeat: no-repeat;
  background-size: 143px 123px;
  background-position: top left;
  @include mobile-above {
    display: none;
  }
  h4 {
    @include fonts("Work Sans", #000000, 32px, 400, 38px, 0.01em);
    text-transform: uppercase;
    text-align: center;
  }
}

.mobile-subscribe-section {
  background: #f4f2f1;
  padding-bottom: 83px;
  width: 100%;
  @include mobile-above {
    display: none;
  }
  h2 {
    margin-bottom: 32px;
  }
  .subscription-card {
    padding: 24px 24px 32px;
    background-color: #fff;
    .subscription-title {
      padding: 20px 12px;
      text-align: center;
      margin-bottom: 16px;
      background: #ffb98e;
      border: 1.4px solid #ffb98e;
      border-radius: 4px;
      @include fonts("Work Sans", #333333, 20px, 500, 24px, 0);
    }
    p {
      padding-top: 16px;
      padding-bottom: 16px;
      text-align: center;
      @include fonts("Inter", #333333, 16px, 600, 16px, 0);
      border-bottom: 1px solid #d6d1ca;
      &::first-child {
        @include fonts("Inter", #333333, 22px, 600, 22px, 0);
      }
      &:last-child {
        border-bottom: 0;
        margin-bottom: 16px;
      }
    }
    span {
      @include fonts("Inter", #727272, 12px, 400, 16px, 0);
      text-align: center;
      display: block;
    }
    .fill-btn {
      width: 100%;
      margin-top: 40px;
    }
  }
  .cert-slider {
    width: 100%;
    position: relative;
    padding-bottom: 75px;
    .tns-nav {
      @include ultra-mobile-above {
        display: none;
      }
    }
    .owl-item,
    .tns-item {
      &.active {
        visibility: visible;
        & ~ .owl-item {
          visibility: visible;
        }
      }
      &.tns-slide-active {
        visibility: visible;
        & ~ .tns-item {
          visibility: visible;
        }
      }
    }

    .tns-outer {
      .tns-ovh {
        overflow: visible;
        position: relative;
        @include ultra-mobile {
          overflow: hidden;
        }
        .tns-controls,
        .tns-nav {
          display: none;
        }
        .tns-inner .tns-slider {
          display: flex;
          justify-content: flex-start;
        }
        &::before {
          position: absolute;
          content: "";
          left: -328px;
          width: 300px;
          height: 100%;
          z-index: 1;
          background-color: $white;
        }
      }
      .tns-controls {
        text-align: right;
        position: absolute;
        right: 0;
        bottom: 0;

        // @include ultra-mobile {
        //   display: none;
        // }
        button {
          span {
            font-size: 0;
          }
        }
        button:first-child {
          height: 43px;
          width: 43px;
          border: 0;
          border: 0;
          background-color: transparent;
          margin: 0;
          content: "";
          @include prefix(border-radius, 0px);
          background-image: url("../../Assets/img/owl-prev.svg");
          background-repeat: no-repeat;
          margin-right: 16px;
          &[disabled] {
            background-image: url("../../Assets/img/owl-disable-prev.svg");
          }
          &:hover {
            background-color: transparent;
          }
        }
        button:last-child {
          height: 43px;
          width: 43px;
          border: 0;
          background-color: transparent;
          margin: 0;
          @include prefix(border-radius, 0px);
          background-image: url("../../Assets/img/owl-next.svg");
          background-repeat: no-repeat;
          &[disabled] {
            background-image: url("../../Assets/img/owl-disable-next.svg");
          }
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}
