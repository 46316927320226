.pdataname-popup {
  @include mobile {
    padding: 0 !important;
    .modal-content {
      background: $color2;
      @include prefix(border-radius, 0);
      height: 100vh;
      border: 0;
      overflow-y: auto;
    }
  }
  .modal-dialog {
    max-width: 360px;
    @include mobile {
      max-width: 100%;
      margin: 0;
    }
    .modal-header {
      justify-content: center;
      border-bottom: 0;
      position: relative;
      padding: 32px 28px;
      @include mobile {
        padding: 40px 28px 26px 28px;
      }
      h5 {
        text-align: center;
        @include fonts("Work Sans", $color1, 24px, 600, 26px, 0);
      }
      .btn-close {
        position: absolute;
        padding: 0;
        left: 28px;
        background: url("../../../../Assets/img/modal-close.svg");
        background-repeat: no-repeat;
        @include hw(28px);
        margin: 0;
        opacity: 1;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .modal-body {
      padding: 0 28px 67px 28px;
      .input-groups {
        margin-bottom: 20px;
        @include mobile {
          input {
            background: transparent;
          }
        }
      }
    }
    .action-bar {
      padding: 0 28px 58px 28px;
      .MuiButton-contained.fill-btn {
        width: 100%;
        margin-top: 36px;
        @include mobile {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}
