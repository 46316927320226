@include ultra-mobile {
  .checkout.header {
    background-color: $white;
    @include prefix(box-shadow, none);
  }
}

.ckheader-container {
  @include flex-between-center;
  padding-left: 28px;
  padding-right: 28px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  @include ultra-mobile {
    @include flex-center-center;
  }
  .logo {
    img {
      display: block;
      height: 36.18px;
      width: auto;
    }
  }
  p {
    color: $white;
  }
}
