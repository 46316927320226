.forgot-password-page{
  h1{
    margin-bottom: 32px;
    @include mobile{
      text-align: center;
      margin-bottom: 21px;
    }
  }
  .fill-btn{
    margin-top: 42px;
  }
  p{
    margin-bottom: 16px;
    @include fonts("Inter", $black, 16px, 400, 24px, 0);
    @include mobile{
      text-align: center;
    }
  }
  .go-back-wrapper{
    text-align: center;
    margin-top: 24px;
  }
}