.input-groups.password {
  .pwd-wrap {
    position: relative;
    @include prefix(display, flex);
    @include prefix(flex-direction, column);
    max-width: 600px;
    .visibilitybtn {
      position: absolute;
      border: 0;
      @include prefix(border-radius, none);
      right: 8px;
      top: 16px;
      &.show {
        background-image: url("../../../Assets/img/pwdshow.svg");
        background-color: transparent;
        @include hw(16px);
      }
      &.hide {
        background-image: url("../../../Assets/img/pwdhidden.svg");
        background-color: transparent;
        @include hw(16px);
      }
    }
  }
  span.error {
    margin-top: 4px;
    // padding-left: 6px;
    @include fonts("Inter", red, 12px, 400, 14px, 0);
  }
}
