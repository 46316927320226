header {
  background-color: $color1;
  padding-top: 10px;
  padding-bottom: 9px;
  box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.06);
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 887;
  @include tablet {
    min-height: 60px;
    padding-top: 16px;
    padding-bottom: 13.53px;
  }
  .header-container {
    padding-left: 28px;
    padding-right: 28px;
    margin-left: auto;
    margin-right: auto;
    @include flex-start-center;
    @include tablet-above {
      max-width: 960px;
      padding-left: 12px;
      padding-right: 12px;
    }
    @include desktop-above {
      max-width: 1140px;
      padding-left: 12px;
      padding-right: 12px;
    }
    @include above-1400 {
      max-width: 1320px;
      padding-left: 12px;
      padding-right: 12px;
    }
    @include above-1440 {
      padding-left: 0;
      padding-right: 0;
      max-width: 1384px;
    }

    .menu-icon {
      @include tablet-above {
        display: none;
      }
      width: 36px;
      height: auto;
    }
    .logo {
      display: block;
      margin-right: 125px;
      @include desktop {
        margin-right: 40px;
      }
      @include tablet {
        position: absolute;
        top: 9px;
        left: 50%;
        @include prefix(transform, translateX(-50%));
      }
      img {
        display: block;
        height: 49px;
        width: auto;
        @include tablet {
          height: 42px;
        }
      }
    }
    .center-menu {
      margin-left: auto;
      margin-right: auto;
      @include flex-start-center;
      li {
        @include prefix(transition, all 300ms ease-in);
        .nav-links {
          @include fonts("Inter", $white, 16px, 500, 32px, 0);
          @include prefix(transition, all 300ms ease-in);
        }
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
        &.active,
        &:hover {
          cursor: pointer;
          .nav-links {
            color: $color5;
          }
        }
      }
      @include tablet {
        display: none;
      }
    }
    .left-menu {
      margin-left: auto;
      @include flex-start-center;
      .fill-btn {
        height: 36px;
        padding-left: 12px;
        padding-right: 12px;
        background-color: $color5;
        color: $color8;
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        width: 174px;
        margin-right: 16px;
        letter-spacing: 0.02em;
        &:hover {
          background-color: $color15;
        }
        @include desktop {
          width: 120px;
        }
        @include tablet {
          display: none;
        }
      }
      .user-icon {
        @include hw(26px);
        margin-right: 16px;
        cursor: pointer;
        .dropdown-toggle {
          background-color: transparent !important;
          outline: none;
          border: 0 !important;
          box-shadow: none !important;
          padding: 0;
          @include hw(26px);
          &::after {
            display: none;
          }
        }
        .dropdown-menu.login-menu {
          border: 0;
          width: 262px;
          // box-shadow: 4px 4px 44px rgba(0, 0, 0, 0.08);
          @include prefix(border-radius, 0);
          inset: unset !important;
          transform: unset !important;
          top: 55px !important;
          right: 0 !important;
          padding: 24px 16px;
          .login-btn {
            height: 36px;
            @include prefix(border-radius, 50px);
            position: relative;
            outline: none;
            border: 0;
            @include fonts("Work Sans", $white, 12px, 500, 16px, 0.02em);
            padding: 0 24px;
            text-align: center;
            box-sizing: border-box;
            box-shadow: none;
            display: inline-flex;
            justify-content: center;
            text-decoration: none;
            align-items: center;
            @include prefix(transition, all 300ms ease-in);
            overflow: hidden;
            background-color: $color1;
            &:hover {
              background-color: $color14;
              text-decoration: none;
              @include prefix(transition, all 300ms ease-in);
              @include prefix(box-shadow, 0px 0px 44px rgba(0, 0, 0, 0.11));
              cursor: pointer;
              box-shadow: none;
            }
            &[disabled] {
              opacity: 0.6;
              background-color: $color1;
              color: $white;
            }
          }
          .have-account-content {
            margin-top: 16px;
            @include fonts("Inter", $color13, 13px, 400, 20px, 0);
            .dropdown-item {
              display: inline-block;
              padding: 0;
              width: auto;
              @include fonts("Inter", $color1, 13px, 600, 20px, 0);
              background-color: transparent !important;
              @include prefix(transition, all 300ms ease-in);
              &:hover {
                color: #42667c;
                text-decoration: underline;
              }
            }
          }
        }
        .dropdown-menu.account-menu {
          border: 0;
          width: 262px;
          // box-shadow: 4px 4px 44px rgba(0, 0, 0, 0.08);
          @include prefix(border-radius, 0);
          inset: unset !important;
          transform: unset !important;
          top: 55px !important;
          right: 0 !important;
          padding: 0;
          h6 {
            @include fonts("Inter", $color8, 16px, 700, 28px, 0);
            padding: 14px 16px;
            background-color: $color11;
          }
          .items-wrapper {
            padding-top: 20px;
            padding-bottom: 20px;
            .dropdown-item {
              padding: 4px 16px;
              padding-right: 46px;
              background-color: transparent !important;
              position: relative;
              @include fonts("Inter", $color8, 14px, 500, 28px, 0);
              &::after {
                @include hw(22px);
                position: absolute;
                content: "";
                right: 16px;
                top: 50%;
                @include prefix(transform, translateY(-50%));
                @include prefix(transition, all 300ms ease-in);
                @include background("../../Assets/img/grey-right.svg");
              }
              &:hover,
              &.active {
                &::after {
                  @include background("../../Assets/img/sidebar-fillnav.svg");
                }
              }
            }
          }
        }
      }
      
      .add-to-cart-popup {
        position: fixed;
        z-index: 888;
      }
    }
  }

  
  &.nav-fixed {
    .cart-menu {
      top: 76px;
    }
  }
  
}





.add-to-cart {
  position: relative;
  cursor: pointer;
  .dropdown-toggle{
    padding: 0;
    background-color: transparent !important;
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
    &::after{
      display: none;
    }
    p {
      position: absolute;
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $black;
      left: 50%;
      top: 4px;
      @include prefix(transform, translateX(-50%));
    }
  }
  
  
}

.dropdown-menu.cart-menu-wrapper {
  border: 0;
  width: 360px;
  // box-shadow: 4px 4px 44px rgba(0, 0, 0, 0.08);
  @include prefix(border-radius, 0);
  inset: unset !important;
  transform: unset !important;
  top: 55px !important;
  right: 0 !important;
  padding: 0;
  @include mobile{
    width: 100%;
    inset: unset !important;
    transform: unset !important;
    top: 0 !important;
    right: 0 !important;
    height: 100vh;
    position: fixed !important;
  }
  h6 {
    @include fonts("Inter", $color8, 16px, 700, 28px, 0);
    padding: 14px 16px;
    background-color: $color11;
  }
  .items-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    .dropdown-item {
      padding: 4px 16px;
      padding-right: 46px;
      background-color: transparent !important;
      position: relative;
      @include fonts("Inter", $color8, 14px, 500, 28px, 0);
      &::after {
        @include hw(22px);
        position: absolute;
        content: "";
        right: 16px;
        top: 50%;
        @include prefix(transform, translateY(-50%));
        @include prefix(transition, all 300ms ease-in);
        @include background("../../Assets/img/grey-right.svg");
      }
      &:hover,
      &.active {
        &::after {
          @include background("../../Assets/img/sidebar-fillnav.svg");
        }
      }
    }
  }
}

.dropdown-menu.cart-menu-wrapper .cart-menu {
  width: 360px;
  background: $color2;
  max-height: calc(100vh - 110px);
  transition: all 300ms ease;
  z-index: 999;
  @include flex-start-start;
  @include prefix(flex-direction, column);
  @include mobile {
    width: 100%;
    max-height: unset;
    height: 100%;
  }
  .minicart-top {
    padding: 22px 28px 20px 28px;
    width: 100%;
    @include cart-height{
      padding: 12px 22px 12px 22px;
    }
    @include mobile {
      padding: 40px 28px 40px 28px;
    }
    .mobile-close {
      border: 0;
      background: transparent;
      box-shadow: none;
      position: absolute;
      height: 28px;
      padding: 0;
      @include ultra-mobile {
        left: 28px;
      }
      @include mobile-above{
        display: none;
      }
    }
    .f2036 {
      color: $color1;
      @include cart-height{
        line-height: 28px;
      }
      @include mobile {
        text-align: center;
        font-size: 24px;
        line-height: 26px;
      }
    }
  }
  .your-bag {
    flex: 1;
    overflow-y: auto;
    width: 100%;
    .cart-box {
      padding: 0 28px;
      @include flex-start-start;
      @include prefix(flex-direction, column);
      @include cart-height{
        padding: 0 22px;
      }
      .cart-data-img {
        width: 84px;
      }
      .cart-data {
        width: calc(100% - 84px);
        padding-left: 16px;
        padding-top: 2px;
        .f1626 {
          margin-bottom: 4px;
        }
        .atrributes {
          margin-bottom: 12px;
          .select-group {
            select {
              height: 24px;
              padding: 0 20px 0 4px;
              background-position: calc(100% - 4px), calc(100% - 4px);
              @include fonts("Inter", $color1, 12px, 400, 18px, 0);
            }
          }
          .with-incdec {
            @include flex-between-center;
            .inc-dec-box {
              width: calc(100% - 144px);
              @include mobile {
                max-width: 60px;
              }
              @include mini-mobile {
                max-width: 60px;
                width: 100%;
              }
            }
            .select-group {
              max-width: 136px;
              @include mobile {
                max-width: calc(100% - 72px);
                width: 100%;
              }
            }
          }
          .inc-dec-box {
            input {
              background-color: transparent;
            }
          }
        }
        .price-action {
          @include flex-between-center;
          // .price-text {
          //   &:first-letter {
          //     @include fonts("Work Sans", $color8, 14px, 500, 26px, 0);
          //   }
          // }
        }
      }
      .item {
        width: 100%;
        @include flex-start-start;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
  }
  .action-bar {
    padding: 32px 28px;
    width: 100%;
    @include cart-height{
      padding: 20px 22px;
    }
    @include mobile{
      padding-bottom: 100px;
    }
    .fill-btn {
      height: 36px;
      width: 100%;
      background-color: $color1;
      @include fonts("Work Sans", $white, 14px, 400, 16px, 0.02em);
      @include tablet {
        display: block;
      }
      @include mobile {
        height: 64px;
        font-weight: 600;
      }
      &:hover {
        background-color: $color14;
      }
    }
  }
  .discount-box {
    padding: 32px 28px;
    width: 100%;
    background-color: #ede2d1;
    @include cart-height{
      padding: 20px 22px
    }
    p {
      @include fonts("Inter", $color8, 16px, 600, 24px, 0);
      margin-bottom: 11px;
    }
    span {
      display: block;
      @include fonts("Inter", $color8, 12px, 400, 20px, 0);
      @include mobile {
        font-size: 16px;
        line-height: 28px;
      }
    }
    .accordion {
      text-transform: uppercase;
      .accordion-item {
        background-color: transparent;
        border: 0;
      }
      .accordion-header {
        .accordion-button.collapsed {
          text-transform: uppercase;
          margin-top: 26px;
          display: block;
          padding: 0;
          width: 100%;
          height: 36px;
          @include fonts("Work Sans", $color8, 14px, 400, 16px, 0.02em);
          @include prefix(border-radius, 50px);
          text-align: center;
          box-sizing: border-box;
          outline: none;
          border: 2px solid $color1;
          box-shadow: none;
          text-decoration: none;
          @include prefix(transition, all 300ms ease-in);
          overflow: hidden;
          background-color: transparent;
          @include cart-height{
            margin-top: 12px;
          }
          &:hover {
            background-color: transparent;
            text-decoration: none;
            @include prefix(transition, all 300ms ease-in);
            color: $color8;
            cursor: pointer;
            border-color: $color14;
            box-shadow: none;
          }
          &[disabled] {
            opacity: 0.6;
          }
          &::after {
            display: none;
          }
          @include mobile {
            height: 64px;
            font-weight: 600;
          }
        }
        .accordion-button {
          text-transform: uppercase;
          margin-top: 26px;
          display: block;
          width: 100%;
          height: 36px;
          background: $color1;
          border: 0;
          text-align: center;
          text-decoration: none;
          @include prefix(border-top-left-radius, 50px);
          @include prefix(border-top-right-radius, 50px);
          @include fonts("Work Sans", $white, 14px, 400, 16px, 0.02em);
          padding: 24px 0 0 0;
          &::after {
            display: none;
          }
          &:focus,
          &:not(.collapsed) {
            @include prefix(box-shadow, none);
          }
          @include mobile {
            font-weight: 600;
          }
        }
      }
      .accordion-collapse {
        margin-top: -1px;
      }
      .accordion-body {
        padding: 24px 30px 32px 30px;
        text-align: center;
        background: $color1;
        @include prefix(border-bottom-left-radius, 50px);
        @include prefix(border-bottom-right-radius, 50px);
        .subs-time {
          @include fonts("Work Sans", $white, 14px, 400, 16px, 0.02em);
          margin-bottom: 0;
          @include mobile {
            font-weight: 600;
          }
          &:not(:last-child) {
            padding-bottom: 16px;
            margin-bottom: 16px;
            border-bottom: 2px solid $white;
          }
        }
      }
    }
  }
}

.cart-back {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background: $black;
  opacity: 0.2;
  height: 100vh;
  z-index: 998;
  display: none;
  &.active {
    display: block;
  }
  @include mobile {
    &.active {
      display: none;
    }
  }
}






.dropdown-back{
  background-color: rgba(51, 51, 51, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  display: none;
  z-index: 88;
  &.show{
    display: block;
  }
}