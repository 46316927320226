.cus-breadcrumb {
  padding: 16px 0;
  @include flex-start-center;
  @include prefix(flex-wrap, wrap);
  a {
    @include fonts("Work Sans", $color8, 12px, 500, 14px, 0);
    @include flex-start-center;
    position: relative;
    &::after {
      content: "/";
      margin: 0 4px;
    }
    &:first-child {
      padding-left: 13px;
      &::before {
        content: " ";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        @include background("../../../Assets/img/breadcrumb-arrow.svg");
        width: 5px;
        height: 8px;
        display: block;
      }
    }
  }
  span {
    @include fonts("Work Sans", $color8, 12px, 300, 14px, 0);
    &::after {
      content: "/";
      margin: 0 4px;
    }
    &:last-child{
      &::after{
        display: none;
      }
    }
  }
}
