.subscribe-newsletter{
  @include mobile{
    text-align: center
  }
  h6{
    @include fonts("Inter", $color8, 20px, 600, 28px, 0);
    margin-bottom: 16px;
  }
  p{
    @include fonts("Inter", $color8, 16px, 400, 28px, 0);
    margin-bottom: 15px;
  }
  .input-btn{
    @include flex-between-start;
    @include desktop{
      display: block;
    }
    .input-groups{
      margin-bottom: 40px;
      margin-top: 10px;
      width: 352px;
      @include mobile{
        width: 100%;
      }
      input{
        background-color: transparent;
      }
    }
    .MuiButton-contained.fill-btn{
      height: 64px;
      width: 352px;
      @include mobile{
        width: 100%;
      }
    }
  }
}