.input-groups {
    @include prefix(display, flex);
    @include prefix(flex-direction, column);
    max-width: 600px;
    position: relative;
    margin-bottom: 16px;
    input {
        width: auto;
        height: 46px;
        padding: 0 0 0 6px;
        outline: 0;
        border: 0;
        border-bottom: 1px solid $color8;
        border-radius: 0;
        background: #fff;
        @include fonts("Inter", $color8, 16px, 600, 24px, 0);
        &.error {
            border-color: red;
        }
    }
    label {
        display: block;
        padding: 0 6px;
        pointer-events: none;
        position: absolute;
        transition: all 0.2s ease-out;
        font-family: Inter;
        font-weight: normal;
        font-size: 12px;
        line-height: 19px;
        color: #727272;
        top: 22px;
        z-index: 2;
    }
    .active {
        top: -4px;
        transform: translateY(0);
    }
    &:focus-within label {
        top: -4px;
        transform: translateY(0);
    }
    span.error {
        @include fonts("Inter", red, 12px, 400, 16px, 0);
        display: block;
        margin-top: 4px;
    }
}