.signup{
  h1{
    @include mobile{
      margin-bottom: 40px;
      order: 1;
      text-align: center;
    }
  }
  .input-field-wrapper{
    @include mobile{
      order: 4;
    }
  }
  .fill-btn{
    margin-top: 32px;
    @include mobile{
      order: 5;
    }
  }
  .or{
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
    position: relative;
    @include fonts("Inter", $color8, 14px, 500, 20px, 0);
    @include mobile{
      order: 3;
    }
    &::before{
      position: absolute;
      content: '';
      height: 1px;
      background-color: $color4;
      left: 0;
      width: calc(50% - 22px);
      top: 50%;
      @include prefix(transfrom, translateY(-50%));
    }
    &::after{
      position: absolute;
      content: '';
      height: 1px;
      background-color: $color4;
      right: 0;
      width: calc(50% - 22px);
      top: 50%;
      @include prefix(transfrom, translateY(-50%));
    }
  }
  .social-login{
    @include flex-center-center;
    @include mobile{
      order: 1;
    }
    // @include mini-mobile{
    //   display: block;
    // }
    .social-btns{
      width: calc(50% - 8px);
      cursor: pointer;
      // @include mini-mobile{
      //   width: 100%;
      // }
    }
  }
  .have-account{
    margin-top: 16px;
    @include fonts("Inter", $color13, 14px, 500, 20px, 0);
    text-align: center;
    @include mobile{
      order: 6;
    }
    a{
      color: $color1;
      font-weight: bold;
      border-bottom: 1px solid transparent;
      &:hover{
        border-color: $color13;
      }
    }
  }
}