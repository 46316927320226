.ck {
  display: inline-block;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  user-select: none;
  margin-bottom: 0;
  @include fonts("Inter", $color8, 14px, 500, 22px, 0);
}
.ck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  @include prefix(transition, all 100ms ease-in);
}
.ck-checkmark {
  position: absolute;
  top: 0;
  left: 3px;
  height: 22px;
  width: 22px;
  border: 2px solid $black;
  box-sizing: border-box;
  background-color: transparent;
  @include prefix(transition, all 100ms ease-in);
  @include prefix(border-radius, 50%);
}

.ck input:checked ~ .ck-checkmark {
  background-color: $color1;
  border-color: $color1;
}
.ck-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.ck input:checked ~ .ck-checkmark:after {
  display: block;
}
.ck .ck-checkmark:after {
  left: 5px;
  top: 1px;
  width: 7px;
  height: 12px;
  border: solid $white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
