.step-wrapper {
    padding-top: 12px;
}
.process-progress {
    @include flex-center-center;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid $color1;
    padding-bottom: 12px;
    .info-progress {
        text-align: center;
        width: calc(100% / 3);
        position: relative;
        z-index: 2;
        @include flex-center-center;
        &:first-child {
            .numbers {
                @include prefix(display, flex);
                &:after {
                    background-image: url("../../../Assets/img/step1-light.svg");
                    @include hw(21px);
                    display: inline-block;
                    content: ' ';
                    margin-right: 4px;
                }
            }
        }
        .numbers {
            @include prefix(display, flex);
            &:after {
                background-image: url("../../../Assets/img/step2-light.svg");
                @include hw(21px);
                display: inline-block;
                content: ' ';
                margin-right: 4px;
            }
        }
        &:last-child {
            .numbers {
                @include prefix(display, flex);
                &:after {
                    background-image: url("../../../Assets/img/step3-light.svg");
                    @include hw(21px);
                    display: inline-block;
                    content: ' ';
                    margin-right: 4px;
                }
            }
        }
        .tab-info {
            @include fonts("Work Sans", #7D7A75, 12px, 400, 24px, 0);
        }
        &.done {
            &:first-child {
                .numbers {
                    &:after {
                        background-image: url("../../../Assets/img/step1-dark.svg");
                    }
                }
            }
            .numbers {
                &:after {
                    background-image: url("../../../Assets/img/step2-dark.svg");
                }
            }
            &:last-child {
                .numbers {
                    &:after {
                        background-image: url("../../../Assets/img/step3-dark.svg");
                    }
                }
            }
            .tab-info {
                color: $color1;
                font-weight: 500;
            }
        }
    }
}
