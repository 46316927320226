// .footer-wrap {
// 	height: 491px;
// 	background-color: $color1;
// 	.container {
// 		@include ultra-desktop{
// 			padding-left: 0;
// 			padding-right: 0;
// 			max-width: 1344px;
// 		}
// 	}
// 	.footer-logo {
// 		padding-top: 94px;
// 		img {
// 			width: auto;
// 			margin: 0 auto;
// 		}
// 	}
// 	.footer-menu {
// 		padding-top: 56px;
// 		ul {
// 			@include flex-center-center;
// 			li {
// 				&:not(:last-child) {
// 					margin-right: 32px;
// 				}
// 			}
// 			.f1624 {
// 				color: $white;
// 				text-transform: uppercase;
// 			}
// 		}
// 	}
// 	.footer-social {
// 		margin-bottom: 80px;
// 		padding-top: 56px;
// 		ul {
// 			@include flex-center-center;
// 			li {
// 				&:not(:last-child) {
// 					margin-right: 32px;
// 				}
// 			}
// 		}
// 	}
// 	.footer-bottom {
// 		border-top: 1px solid $color2;
// 		padding-top: 16px;
// 		span {
// 			color: $white;
// 		}
// 	}
// }

// @include mobile {
// 	.footer-wrap {
// 		height: 592px;
// 		.footer-logo {
// 			padding-top: 68px;
// 		}
// 		.footer-menu {
// 			padding-top: 44px;
// 			ul {
// 				flex-direction: column;
//     		align-items: center;
// 				li {
// 					&:not(:last-child) {
// 						margin-right: 0;
// 						margin-bottom: 22px;
// 					}
// 				}
// 			}
// 		}
// 		.footer-social {
// 			margin-bottom: 36px;
// 			padding-top: 44px;
// 		}
// 		.footer-bottom {
// 			text-align: center;
// 			border-top: 0;
// 		}
// 	}
// }

header{
	&+.footer-wrapper {
		margin-top: 500px;
	}
}
.footer-wrapper{
	&.remove-footer{
		display: none;
	}
	.container {
		@include above-1440{
			padding-left: 0;
			padding-right: 0;
			max-width: 1344px;
		}
	}
	.subscribe-wrapper{
		@include ultra-mobile-above{
			display: none;
		}
		padding-top: 40px;
		padding-bottom: 40px;
		background-color: $color4;
	}
	.footer-menu-wrapper{
		background-color: $color1;
		.footer-up-layout{
			padding-top: 104px;
			padding-bottom: 104px;
			@include flex-between-start;
			@include ultra-mobile{
				display: block;
				padding-top: 72px;
				padding-bottom: 72px;
			}
			.footer-left-section{
				@include mobile{
					text-align: center;
				}
				.footer-logo{
					display: inline-block;
					margin-bottom: 30px;
					img{
						height: 60px;
						width: auto;
						display: block;
					}
				}
				.subscribe-newsletter{
					width: 521px;
					@include desktop{
						width: 352px;
					}
					@include tablet{
						width: 240px
					}
					@include ultra-mobile{
						display: none
					}
					h6{
						@include fonts("Inter", $white, 20px, 600, 28px, 0);
						margin-bottom: 12px;
					}
					p{
						@include fonts("Inter", $white, 14px, 400, 28px, 0);
						margin-bottom: 15px;
					}
					.input-btn{
						@include flex-between-start;
						@include desktop{
							display: block;
						}
						.input-groups{
							margin-bottom: 0;
							margin-top: 10px;
							width: calc(100% - 286px);
							@include desktop{
								width: 100%;
								margin-bottom: 16px;
							}
							label{
								color: $white;
							}
							input{
								color: $white;
								background-color: transparent;
								border-color: $white;
							}
						}
						.MuiButton-contained.fill-btn{
							height: 57px;
							width: 270px;
							margin-left: 16px;
							padding-left: 12px;
							padding-right: 12px;
							background-color: #FFF;
							@include fonts("Work Sans", $black, 12px, 600, 14px, 0.02em);
							@include desktop{
								width: 100%;
								margin-left: 0;
							}
							&:hover{
								color: $black;
								background-color: $color2;
							}
						}
					}
				}
			}
			.footer-right-section{
				padding-top: 10px;
				@include flex-start-start;
				@include mobile{
					display: block
				}
				.f-menu-box{
					p{
						@include fonts("Work Sans", #99B9CE, 20px, 500, 24px, 0);
						margin-bottom: 24px;
					}
					@include ultra-desktop{
						margin-right: 136px;
						&:last-child{
							margin-right: 0;
						}
					}
					margin-right: 80px;
					@include tablet{
						margin-right: 36px;
						p{
							@include fonts("Work Sans", #99B9CE, 16px, 500, 18px, 0);
						}
					}
					@include ultra-mobile{
						margin-right: 90px;
						p{
							@include fonts("Work Sans", #99B9CE, 20px, 500, 24px, 0);
						}
					}
					@include mobile{
						margin-right: 0;
						margin-bottom: 48px;
						p{
							text-align: center;
						}
					}
					&:last-child{
						margin-right: 0;
						margin-bottom: 0;
					}
				}
				ul{
					li{
						margin-bottom: 24px;
						@include mobile{
							text-align: center;
						}
						&:last-child{
							margin-bottom: 0
						}
						a{
							@include fonts("Work Sans", $white, 20px, 500, 24px, 0);
							@include prefix(transition, all 300ms ease-in);
							display: inline-block;
							@include tablet{
								@include fonts("Work Sans", $white, 16px, 500, 18px, 0);
							}
							@include ultra-mobile{
								@include fonts("Work Sans", $white, 20px, 500, 24px, 0);
							}
							&:hover{
								color: $color5;
							}
						}
					}
				}
			}
		}
		.copy-right-section{
			background-color: #426174;
			padding: 23px 0;
			@include mobile{
				background-color: transparent;
				padding-top: 0;
				padding-bottom: 72px
			}
			.copy-contents{
				@include flex-between-center;
				@include mobile{
					@include prefix(flex-direction, column-reverse);
				}
				p{
					@include fonts("Inter", $white, 14px, 500, 32px, 0);
				}
				.WI-icons{
					@include flex-center-center;
					@include mobile{
						margin-bottom: 32px;
					}
					a{
						display: block;
						margin-right: 34px;
						@include mobile{
							margin-right: 32px;
						}
						&:last-child{
							margin-right: 0;
						}
						img{
							width: 45px;
							height: auto;
							@include mobile{
								width: 42px;
							}
						}
					}
				}
			}
		}
	}
}