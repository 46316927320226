.MuiButton-contained.fill-btn {
  height: 64px;
  @include prefix(border-radius, 50px);
  position: relative;
  outline: none;
  border: 0;
  @include fonts("Work Sans", $white, 16px, 500, 16px, 0.02em);
  padding: 0 24px;
  text-align: center;
  box-sizing: border-box;
  box-shadow: none;
  display: inline-flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  @include prefix(transition, all 300ms ease-in);
  overflow: hidden;
  background-color: $color1;
  &:hover {
    background-color: $color14;
    text-decoration: none;
    @include prefix(transition, all 300ms ease-in);
    @include prefix(box-shadow, 0px 0px 44px rgba(0, 0, 0, 0.11));
    cursor: pointer;
    box-shadow: none;
  }
  &[disabled] {
    opacity: 0.6;
    background-color: $color1;
    color: $white;
  }
}

.MuiButton-outlined.border-btn {
  height: 64px;
  @include prefix(border-radius, 50px);
  position: relative;
  outline: none;
  border: 2px solid $color1;
  @include fonts("Work Sans", $color8, 16px, 500, 16px, 0.02em);
  padding: 0 20px;
  text-align: center;
  box-sizing: border-box;
  box-shadow: none;
  display: inline-flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  @include prefix(transition, all 300ms ease-in);
  overflow: hidden;
  background-color: transparent;
  &:hover {
    background-color: transparent;
    text-decoration: none;
    @include prefix(transition, all 300ms ease-in);
    color: $color8;
    cursor: pointer;
    border: 2px solid $color14;
    box-shadow: none;
  }
  &[disabled] {
    opacity: 0.6;
  }
  &:hover:not([disabled]) {
    border: 2px solid $color14;
  }
}

.loader-white-wrapper,
.loader-black-wrapper {
  @include flex-center-center;
}
.loader-white {
  width: 8px;
  height: 8px;
  left: -8px;
  border-radius: 50%;
  display: block;
  position: relative;
  color: #fff;
  -webkit-animation: animloaderWhite 2s linear infinite;
  animation: animloaderWhite 2s linear infinite;
}

@keyframes animloaderWhite {
  0% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 -2px, -10px 0 0 -2px;
  }
  25% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 -2px, -10px 0 0 -2px;
  }
  50% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 -2px, -10px 0 0 2px;
  }
  75% {
    box-shadow: 10px 0 0 2px, 30px 0 0 -2px, -10px 0 0 -2px;
  }
  100% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 2px, -10px 0 0 -2px;
  }
}
@-webkit-keyframes animloaderWhite {
  0% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 -2px, -10px 0 0 -2px;
  }
  25% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 -2px, -10px 0 0 -2px;
  }
  50% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 -2px, -10px 0 0 2px;
  }
  75% {
    box-shadow: 10px 0 0 2px, 30px 0 0 -2px, -10px 0 0 -2px;
  }
  100% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 2px, -10px 0 0 -2px;
  }
}

.loader-black {
  width: 8px;
  height: 8px;
  left: -8px;
  border-radius: 50%;
  display: block;
  position: relative;
  color: #fff;
  -webkit-animation: animloaderBlack 2s linear infinite;
  animation: animloaderBlack 2s linear infinite;
}

@keyframes animloaderBlack {
  0% {
    box-shadow: 10px 0 0 -2px $color1, 30px 0 0 -2px $color1, -10px 0 0 -2px $color1;
  }
  25% {
    box-shadow: 10px 0 0 -2px $color1, 30px 0 0 -2px $color1, -10px 0 0 -2px $color1;
  }
  50% {
    box-shadow: 10px 0 0 -2px $color1, 30px 0 0 -2px $color1, -10px 0 0 2px $color1;
  }
  75% {
    box-shadow: 10px 0 0 2px $color1, 30px 0 0 -2px $color1, -10px 0 0 -2px $color1;
  }
  100% {
    box-shadow: 10px 0 0 -2px $color1, 30px 0 0 2px $color1, -10px 0 0 -2px $color1;
  }
}
@-webkit-keyframes animloaderBlack {
  0% {
    box-shadow: 10px 0 0 -2px $color1, 30px 0 0 -2px $color1, -10px 0 0 -2px $color1;
  }
  25% {
    box-shadow: 10px 0 0 -2px $color1, 30px 0 0 -2px $color1, -10px 0 0 -2px $color1;
  }
  50% {
    box-shadow: 10px 0 0 -2px $color1, 30px 0 0 -2px $color1, -10px 0 0 2px $color1;
  }
  75% {
    box-shadow: 10px 0 0 2px $color1, 30px 0 0 -2px $color1, -10px 0 0 -2px $color1;
  }
  100% {
    box-shadow: 10px 0 0 -2px $color1, 30px 0 0 2px $color1, -10px 0 0 -2px $color1;
  }
}
