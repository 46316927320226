.banner-wrap {
	.desktop-inwrap {
		position: relative;
		.banner-overlay {
			position: relative;
		}
		.banner-content {
			position: absolute;
			top: 50%;
			@include prefix(transform, translateY(-50%));
			left: 0;
			right: 0;
			width: 100%;
			.container {
				@include desktop-above {
					padding-left: 0;
					padding-right: 0;
					max-width: 1110px;
				}
			}
			.banner-inner {
				width: 403px;
			}
			// position: absolute;
			// max-width: 403px;
			// top: 40%;
			// transform: translateY(-40%);
			// left: 10%;
			// @include tablet {
			// 	left: 8% !important;
			// }
			// @include desktop {
			//   left: 12%
			// }
			// @media (min-width: 1600px) {
			//   left: 15%;
			// }
			// @media (min-width: 1900px) {
			//   left: 20%;
			// }
			h5 {
				@include fonts("Work Sans", $white, 48px, 600, 52px, 0);
				margin-bottom: 8px;
			}
			h6 {
				@include fonts("Inter", $white, 24px, 400, 36px, 0);
			}
			.MuiButton-contained.fill-btn {
				margin-top: 56px;
				width: 304px;
				background-color: $white;
				@include fonts("Work Sans", $color1, 16px, 500, 16px, 0);
				@include tablet {
					margin-top: 24px;
				}
				&:hover {
					background-color: $color2;
				}
				&.mobile-btn{
					display: none;
					@include mobile{
						display: inline-flex;
					}
				}
				&.web-btn{
					@include mobile{
						display: none;
					}
				}
			}
		}
	}
	.mobile-inwrap {
		position: relative;
		margin-top: -60px;
		.banner-content {
			padding: 0 28px;
			position: absolute;
			top: 90px;
			text-align: center;
			width: 100%;
			@include mobile-above {
				top: 20% !important;
			}
			@include iphone-above {
				top: 15%;
			}
			h5 {
				@include fonts("Work Sans", $white, 34px, 600, 36px, 0);
				margin-bottom: 8px;
			}
			h6 {
				@include fonts("Inter", $white, 16px, 400, 24px, 0);
				max-width: 264px;
				margin: 0 auto;
				@include mobile {
					max-width: 300px;
				}
			}
			.MuiButton-contained.fill-btn {
				margin-top: 56px;
				width: 304px;
				background-color: $white;
				@include fonts("Work Sans", $color1, 16px, 500, 16px, 0);
			}
		}
		.actions {
			text-align: center;
			position: absolute;
			bottom: 34px;
			width: 100%;
			.MuiButton-contained.fill-btn {
				width: calc(100% - 56px);
				background-color: $white;
				@include fonts("Work Sans", $color1, 16px, 500, 16px, 0);
			}
		}
	}
}

.qualitypt-wrap {
	text-align: center;
	background-color: $color2;
	padding: 104px 0;
	@include above-1440 {
		.container {
			max-width: 1136px;
			padding-left: 0;
			padding-right: 0;
			.row {
				margin-left: -40px;
				margin-right: -40px;
				> * {
					padding-left: 40px;
					padding-right: 40px;
				}
			}
		}
	}
	.quality-title {
		max-width: 384px;
		margin: 0 auto;
		padding-bottom: 72px;
	}
	.point-wrap {
		.for-img {
			img {
				margin: 0 auto;
				max-width: 104px;
			}
		}
		.for-desc {
			margin-top: 32px;
			h4 {
				text-transform: uppercase;
				color: $color1;
				max-width: 164px;
				margin: 0 auto 8px auto;
				@include mobile{
					margin-bottom: 0;
				}
			}
			.f1422 {
				font-weight: 400;
				@include mobile{
					display: none;
				}
			}
		}
	}
	.action-bar {
		margin-top: 76px;
		.MuiButton-outlined.border-btn {
			width: 100%;
			max-width: 304px;
			@include mobile {
				max-width: 100%;
			}
		}
	}
}

@include tablet {
	.qualitypt-wrap {
		padding: 64px 0;
		.quality-title {
			padding: 0 14px 40px 14px;
		}
		.point-wrap {
			margin-bottom: 48px;
			padding: 0 14px;
		}
		.action-bar {
			margin-top: 16px;
			padding: 0 14px;
		}
	}
}

@include mobile {
	.qualitypt-wrap {
		padding: 64px 0;
		.quality-title {
			padding-bottom: 28px;
			max-width: 100%;
		}
		.point-wrap {
			margin-bottom: 48px;
			.for-desc {
				margin-top: 28px;
				h4 {
					margin-bottom: 4px;
					max-width: 100%;
				}
				.f1422 {
					@include fonts("Inter", $color8, 16px, 400, 26px, 0);
				}
			}
		}
		.action-bar {
			margin-top: 8px;
		}
	}
}

.homepage{
	.clubpart-wrap {
		@include mobile {
			display: none;
		}
	}
}
.clubpart-wrap {
	.club-inwrap {
		@include prefix(display, flex);
	}
	.club-left {
		width: 50%;
		max-width: 50%;
	}
	.club-right {
		width: 50%;
		max-width: 50%;
		background-color: $color5;
		@include flex-center-center;
		h1 {
			max-width: 480px;
		}
		.cpoint-details {
			margin-top: 56px;
			@include flex-start-start;
			.point {
				&:not(:first-child) {
					padding-left: 48px;
				}
				.cp-img {
					img {
						max-width: 104px;
					}
				}
				.cp-text {
					margin-top: 32px;
					h4 {
						max-width: 200px;
						text-transform: uppercase;
					}
				}
			}
		}
		.action-bar {
			margin-top: 56px;
			.MuiButton-outlined.border-btn {
				width: 100%;
				max-width: 304px;
				@include mobile {
					max-width: 100%;
				}
			}
		}
	}
}

@include desktop {
	.clubpart-wrap {
		.club-right {
			padding: 0 48px;
			.cpoint-details {
				.point {
					&:not(:first-child) {
						padding-left: 24px;
					}
				}
			}
		}
	}
}

@include tablet {
	.clubpart-wrap {
		.club-left {
			display: none;
		}
		.club-right {
			padding: 40px 0;
			width: 100%;
			max-width: 100%;
			.cpoint-details,
			.action-bar {
				margin-top: 40px;
			}
		}
	}
}

@include mobile {
	.clubpart-wrap {
		.club-head {
			p {
				margin-top: 16px;
			}
		}
		.club-right {
			padding: 64px 28px;
			width: 100%;
			max-width: 100%;
			.cpoint-details {
				margin-top: 0;
				flex-direction: column;
				.point {
					margin-top: 32px;
					@include flex-center-center;
					&:not(:first-child) {
						padding-left: 0;
					}
					.cp-text {
						margin-top: 0;
						padding-left: 24px;
					}
				}
			}
			.action-bar {
				margin-top: 54px;
				.MuiButton-root.MuiButton-text.MuiButtonBase-root.border-btn {
					width: 100%;
				}
			}
		}
	}
}

.premium-wrap {
	background-color: $color2;
	@include mobile {
		display: none;
	}
	.pm-inwrap {
		background-image: url("../../Assets/img/premium-bgimg.svg");
		background-repeat: no-repeat;
		background-position-y: -500px;
		background-position-x: 20%;
		.pm-content {
			padding-top: 316px;
			padding-bottom: 240px;
			text-align: center;
			p {
				text-transform: uppercase;
				max-width: 372px;
				text-align: center;
				margin: 0 auto 40px auto;
			}
			.action-bar {
				.MuiButton-contained.fill-btn {
					background-color: $color5;
					color: $color8;
					max-width: 304px;
					width: 100%;
					&:hover {
						background-color: $color15;
					}
					@include mobile {
						max-width: 100%;
					}
				}
			}
		}
	}
}

@include tablet {
	.premium-wrap {
		.pm-inwrap {
			background-position-x: -150px;
			.pm-content {
				padding-top: 280px;
				padding-bottom: 200px;
			}
		}
	}
}

@include ultra-mobile {
	.premium-wrap {
		.pm-inwrap {
			background-position-x: -200px;
		}
	}
}

@include mobile {
	.premium-wrap {
		.pm-inwrap {
			background-image: url("../../Assets/img/mob-premium-bgimg.svg");
			background-position-x: 0;
			background-position-y: -100px;
			.pm-content {
				padding: 104px 28px;
			}
		}
	}
}

.certificate-wrap {
	.cert-inwrap {
		padding-top: 120px;
		padding-bottom: 124px;
		background-image: url("../../Assets/img/certificate-vector.svg");
		background-repeat: no-repeat;
		background-position-y: 50px;
		overflow: hidden;
		& > .container {
			@include above-1400 {
				max-width: 1184px;
				padding-left: 0;
				padding-right: 0;
			}
		}
		.cert-wrapper {
			@include flex-center-center;
			@include ultra-mobile {
				display: block;
			}
		}
		.cert-desc {
			width: 547px;
			padding-right: 105px;
			position: relative;
			z-index: 3;
			@include desktop {
				width: 400px;
				padding-right: 32px;
			}
			@include ultra-mobile {
				width: 100%;
				padding-right: 0;
			}
			p {
				margin-top: 32px;
			}
		}
		.cert-slider {
			width: calc(100% - 547px);
			@include ultra-mobile-above {
				padding-bottom: 48px;
				position: relative;
			}
			@include desktop {
				width: calc(100% - 400px);
			}
			@include ultra-mobile {
				width: 100%;
				padding-bottom: 17px;
				position: relative;
			}
			.tns-nav {
				@include ultra-mobile-above {
					display: none;
				}
			}
			.owl-item,
			.tns-item {
				padding: 40px 10px;
				visibility: hidden;
				@include ultra-mobile {
					padding: 0;
				}
				&.active {
					visibility: visible;
					& ~ .owl-item {
						visibility: visible;
					}
				}
				&.tns-slide-active {
					visibility: visible;
					& ~ .tns-item {
						visibility: visible;
					}
				}
			}
			.cert-card {
				padding: 56px 40px 0 40px;
				box-shadow: 4px 4px 44px rgba(0, 0, 0, 0.08);
				min-height: 430px;
				height: 100%;
				text-align: center;
				@include ultra-mobile {
					box-shadow: none;
					padding: 48px 0px 36px 0px;
					min-height: auto;
					max-width: 304px;
					margin-left: auto;
					margin-right: auto;
				}
				.cert-img {
					img {
						max-width: 145px;
						margin: 0 auto;
					}
				}
				.cert-title {
					margin: 24px auto 0 auto;
					max-width: 228px;
				}
				.cert-msg {
					margin-top: 12px;
				}
			}
			.owl-carousel {
				.owl-stage-outer {
					overflow: visible;
					@include ultra-mobile {
						overflow: hidden;
					}
					.owl-stage {
						display: flex;
						justify-content: flex-start;
					}
					&::before {
						position: absolute;
						content: "";
						left: -328px;
						width: 300px;
						height: 100%;
						z-index: 1;
						background-color: $white;
					}
				}
				.owl-nav {
					margin-top: 0;
					text-align: right;
					button {
						span {
							font-size: 0;
						}
					}
					.owl-prev {
						height: 43px;
						width: 43px;
						margin: 0;
						@include prefix(border-radius, 0px);
						background-image: url("../../Assets/img/owl-prev.svg");
						background-repeat: no-repeat;
						margin-right: 16px;
						&.disabled {
							background-image: url("../../Assets/img/owl-disable-prev.svg");
						}
						&:hover {
							background-color: transparent;
						}
					}
					.owl-next {
						height: 43px;
						width: 43px;
						margin: 0;
						@include prefix(border-radius, 0px);
						background-image: url("../../Assets/img/owl-next.svg");
						background-repeat: no-repeat;
						&.disabled {
							background-image: url("../../Assets/img/owl-disable-next.svg");
						}
						&:hover {
							background-color: transparent;
						}
					}
				}
			}

			.tns-outer {
				.tns-ovh {
					overflow: visible;
					position: relative;
					@include ultra-mobile {
						overflow: hidden;
					}
					.tns-controls,
					.tns-nav {
						display: none;
					}
					.tns-inner .tns-slider {
						display: flex;
						justify-content: flex-start;
					}
					&::before {
						position: absolute;
						content: "";
						left: -328px;
						width: 300px;
						height: 100%;
						z-index: 1;
						background-color: $white;
					}
				}
				.tns-controls {
					margin-top: 0;
					text-align: right;
					@include ultra-mobile-above {
						position: absolute;
						right: 0;
						bottom: 0;
					}
					@include ultra-mobile {
						display: none;
					}
					button {
						span {
							font-size: 0;
						}
					}
					button:first-child {
						height: 43px;
						width: 43px;
						border: 0;
						border: 0;
						background-color: transparent;
						margin: 0;
						content: "";
						@include prefix(border-radius, 0px);
						background-image: url("../../Assets/img/owl-prev.svg");
						background-repeat: no-repeat;
						margin-right: 16px;
						&[disabled] {
							background-image: url("../../Assets/img/owl-disable-prev.svg");
						}
						&:hover {
							background-color: transparent;
						}
					}
					button:last-child {
						height: 43px;
						width: 43px;
						border: 0;
						background-color: transparent;
						margin: 0;
						@include prefix(border-radius, 0px);
						background-image: url("../../Assets/img/owl-next.svg");
						background-repeat: no-repeat;
						&[disabled] {
							background-image: url("../../Assets/img/owl-disable-next.svg");
						}
						&:hover {
							background-color: transparent;
						}
					}
				}
			}
		}
	}
}

@include ultra-mobile {
	.certificate-wrap {
		display: none;
		.cert-inwrap {
			padding-top: 70px;
			padding-bottom: 65px;
			background-image: url("../../Assets/img/mob-certificate-vector.svg");
			background-position-x: right;
			background-position-y: 64px;
		}
	}
}

.searchfor-section {
	padding-top: 77px;
	padding-bottom: 31px;
	background: url(../../Assets/img/search-shape.svg);
	background-repeat: no-repeat;
	background-size: 143px 134px;
	background-position: top left;
	@include mobile-above {
		display: none;
	}
	h4 {
		@include fonts("Work Sans", #000000, 32px, 600, 36px, 0);
		margin-bottom: 16px;
	}
	p {
		@include fonts("Inter", #333333, 16px, 700, 28px, 0);
	}
	span {
		display: block;
		@include fonts("Inter", #333333, 16px, 400, 28px, 0);
	}
	label {
		margin-top: 24px;
		@include fonts("Inter", #333333, 12px, 400, 28px, 0);
	}
}

.range-section {
	padding-bottom: 63px;
	@include mobile-above {
		display: none;
	}
	.star-box {
		background: #f4f2f1;
		border-radius: 2px;
		padding-top: 40px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 46px;
		margin-top: -40px;
		margin-bottom: 40px;
		position: relative;
		z-index: 2;
		@include flex-center-center;
		flex-direction: column;
		ul {
			@include flex-center-center;
			margin-bottom: 8px;
			li {
				height: 22px;
				width: 33px;
				margin-right: 7px;
				&:last-child {
					margin-right: 0;
				}
				img {
					width: 100%;
					height: auto;
				}
			}
		}

		p {
			@include fonts("Inter", #333333, 16px, 500, 28px, 0);
			text-align: center;
			margin-bottom: 16px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	& > p {
		@include fonts("Inter", #333333, 16px, 400, 28px, 0);
	}
	.fill-btn {
		margin-top: 32px;
		background-color: $color5;
		@include fonts("Work Sans", $color8, 14px, 500, 16px, 0.02em);
		width: 100%;
		&:hover {
			background-color: $color15;
		}
	}
}

.tested-section {
	padding-top: 14px;
	padding-bottom: 124px;
	background: url(../../Assets/img/tested-shape.svg);
	background-repeat: no-repeat;
	background-size: 89px 153px;
	background-position: top right;
	@include mobile-above {
		display: none;
	}
	h2 {
		@include fonts("Work Sans", #000000, 32px, 600, 36px, 0);
		margin-bottom: 40px;
	}
	.this-tested-box {
		text-align: center;
		margin-bottom: 48px;
		&:last-child {
			margin-bottom: 0;
		}
		img {
			width: 145px;
			height: auto;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 28px;
		}
		p {
			@include fonts("Work Sans", #000000, 18px, 600, 24px, 0);
			text-transform: uppercase;
			margin-bottom: 12px;
		}
		span {
			@include fonts("Inter", #333333, 16px, 400, 26px, 0);
		}
	}
}

.pural-mappies-section {
	padding-top: 114px;
	padding-bottom: 136px;
	background-color: #ffb98e;
	@include mobile-above {
		display: none;
	}
	img {
		width: 132px;
		height: auto;
		margin-left: auto;
		margin-right: auto;
	}
	h4 {
		@include fonts("Work Sans", #000000, 32px, 400, 38px, 0.01em);
		padding-left: 20px;
		padding-right: 20px;
		margin-top: -45px;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 40px;
	}
	.MuiButton-contained.fill-btn {
		width: 100%;
		background-color: $white;
		@include fonts("Work Sans", #333333, 16px, 500, 16px, 0.02em);
		&:hover {
			background-color: $color2;
		}
	}
}

.join-our-club {
	padding-top: 77px;
	padding-bottom: 72px;
	background: url(../../Assets/img/join-shape.svg);
	background-repeat: no-repeat;
	background-size: 143px 123px;
	background-position: top left;
	@include mobile-above {
		display: none;
	}
	h4 {
		@include fonts("Work Sans", #000000, 32px, 600, 36px, 0);
		margin-bottom: 16px;
	}
	p {
		@include fonts("Inter", #333333, 16px, 400, 28px, 0);
	}
	.border-btn {
		margin-top: 40px;
		width: 100%;
	}
}

.testing-section {
	background: #f4f2f1;
	padding-top: 40px;
	padding-bottom: 104px;
	@include mobile-above {
		display: none;
	}
	p {
		@include fonts("Inter", #333333, 12px, 400, 28px, 0);
		a {
			@include fonts("Inter", #333333, 12px, 700, 28px, 0);
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	.here-link {
		@include fonts("Inter", #333333, 12px, 700, 28px, 0);
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}
