$color1: #4E738A;
$color2: #F4F2F1;
$color3: #CEDFD7;
$color4: #D6D1CA;
$color5: #FFB98E;
$color6: rgba(150, 218, 234, 0.9);
$color7: rgba(253, 215, 86, 0.9);
$color8: #333333;
$white: #ffffff;
$black: #000000;
$color11: #F2F2F2;
$color12: #E19C72;
$color13: #727272;
$color14: #3A586A;
$color15: #F4A675;