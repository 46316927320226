.address-popup {
  @include mobile {
    padding: 0 !important;
    .modal-content {
      background: $color2;
      border: 0;
      @include prefix(border-radius, 0);
    }
  }
  .modal-dialog {
    max-width: 360px;
    @include mobile {
      max-width: 100%;
      margin: 0;
    }
    .modal-header {
      justify-content: center;
      border-bottom: 0;
      position: relative;
      padding: 32px 28px;
      @include mobile {
        padding: 40px 28px 26px 28px;
      }
      h5 {
        text-align: center;
        @include fonts("Work Sans", $color1, 24px, 600, 26px, 0);
      }
      .btn-close {
        position: absolute;
        padding: 0;
        left: 28px;
        background: url("../../../../Assets/img/modal-close.svg");
        background-repeat: no-repeat;
        @include hw(28px);
        margin: 0;
        opacity: 1;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .modal-body {
      padding: 0 28px 0 28px;
      .address-form {
        .input-groups , .select-group{
          margin-bottom: 20px;
          @include mobile {
            input {
              background: transparent;
            }
          }
        }
        .ck{
          margin-bottom: 12px
        }
        .ck.default-checked{
          background: $color5;
          width: 100%;
          border-radius: 8px;
        }
        .half {
          @include flex-between-center;
          .input-groups {
            width: calc(50% - 4px);
            input {
              width: 100%;
            }
          }
        }
        .phone {
          @include prefix(flex-direction, row);
          @include prefix(justify-content, space-between);
          select {
            max-width: 64px;
            padding: 0 4px 0 6px;
            background-position: calc(100% - 4px), calc(100% - 8px);
            @include fonts("Inter", $color8, 14px, 500, 24px, 0);
          }
          .input-groups {
            width: calc(100% - 72px);
            margin-bottom: 0;
          }
        }
      }
    }
    .action-bar {
      padding: 36px 28px 67px 28px;
      .MuiButton-contained.fill-btn {
        width: 100%;
        @include mobile {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}
