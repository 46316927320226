.contact-wrap {
  .inner-contact {
    background-image: url(../../Assets/img/contactus-bg1.svg), url(../../Assets/img/contactus-bg2.svg);
    background-repeat: no-repeat;
    background-position: right top, left 400px;
    @include ultra-mobile {
      background-image: url(../../Assets/img/contactus-mob-bg.svg);
      background-repeat: no-repeat;
      background-position: right 55%;
    }
  }
  .cform-wrap {
    padding: 84px 0 104px 0;
    margin: 0 auto;
    max-width: 600px;
    @include ultra-mobile {
      padding: 35px 0px 104px 0px;
    }
    @include mobile {
      padding-top: 81px;
    }
    .mobile-contect-banner-data {
      @include mobile-above {
        display: none;
      }
      h1 {
        margin-bottom: 24px;
        color: #000;
      }
      p {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: #000;
        margin-bottom: 20px;
      }
      span {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #000;
      }
    }
    .contact-form {
      margin-top: 56px;
      @include ultra-mobile {
        margin-top: 48px;
      }
      > * {
        &:not(:last-child) {
          margin-bottom: 30px;
          @include ultra-mobile {
            margin-bottom: 24px;
          }
        }
      }
      .input-groups {
        input {
          background-color: transparent;
        }
      }
      .textarea-groups {
        textarea {
          background-color: transparent;
        }
      }
    }
    .action-bar {
      margin-top: 56px;
      .MuiButton-contained.fill-btn {
        max-width: 304px;
        width: 100%;
        @include mobile {
          max-width: 100%;
        }
      }
      @include ultra-mobile {
        margin-top: 48px;
        .MuiButton-contained.fill-btn {
          width: 100%;
        }
      }
    }
  }
}
