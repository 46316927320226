.inc-dec-box {
	@include flex-start-center;
	display: inline-flex;
	padding-bottom: 8px;
	border-bottom: 1px solid $color8;
	button {
    border: 0;
    outline: 0 !important;
    height: 13px;
    width: 13px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: transparent;
		cursor: pointer;
    margin: 0 4px;
    img{
      height: 13px;
      width: 13px;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
	input {
    display: block;
    border: 0;
    width: unset;
    min-width: 20px;
    padding: 0;
    max-width: 20px;
    text-align: center;
		@include fonts("Inter", $color1, 14px, 500, 18px, 0);
  }
}
