.payment-steps {
    padding: 40px 28px 32px 28px;
    border: 1px solid $color4;
    @include tablet {
        padding: 32px 20px 24px 20px;
    }
    .methodlist {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
        label {
            cursor: pointer;
        }
    }
    .details {
        &.hide {
            display: none;
        }
        .ccard-form {
            padding-bottom: 28px;
        }
        .half {
            @include flex-between-center;
            .input-groups {
                width: calc(50% - 12px);
                input {
                    width: 100%;
                }
            }
        }
    }
}