.product-detail {
  @include above-1440 {
    .container {
      padding: 0;
      max-width: 1376px;
    }
  }
  @include ultra-mobile {
    .container {
      padding: 0 28px;
    }
  }
  .details-wrap {
    @include prefix(display, flex);
    @include prefix(align-items, center);
    padding: 32px 0;
    @include tablet {
      @include prefix(align-items, start);
    }
    @include ultra-mobile {
      @include prefix(flex-direction, column);
      padding: 4px 0 64px 0;
    }
    .owl-theme .owl-dots {
      .owl-dot {
        @include ultra-mobile-above {
          @include hw(12px);
        }
      }
    }
    .for-img {
      width: 696px;
      position: relative;
      @include ultra-desktop-below {
        width: 640px;
      }
      @include media-1299-below {
        width: 600px;
      }
      @include desktop {
        width: 520px;
      }
      @include tablet {
        width: 50%;
      }
      @include ultra-mobile {
        width: 100%;
      }
      .owl-theme {
        .owl-dots {
          margin-top: 0;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 24px;
          @include ultra-mobile {
            bottom: 16px;
          }
        }
      }
      .tns-outer .tns-nav {
        margin-top: 0;
        z-index: 2;
        position: absolute;
        bottom: 32px;
        left: 50%;
        @include prefix(transform, translateX(-50%));
        @include flex-center-center;
        @include mobile {
          bottom: 17px;
        }
        button {
          margin: 0 8px;
          @include hw(12px);
          padding: 0;
          border: 0;
          @include mobile {
            margin: 0 6px;
            @include hw(10px);
          }
          &:nth-child(5n - 4) {
            @include background("../../Assets/img/Dot-1.svg");
            &.tns-nav-active {
              @include background("../../Assets/img/Dot-fill-1.svg");
            }
          }
          &:nth-child(5n - 3) {
            @include background("../../Assets/img/Dot-2.svg");
            &.tns-nav-active {
              @include background("../../Assets/img/Dot-fill-2.svg");
            }
          }
          &:nth-child(5n - 2) {
            @include background("../../Assets/img/Dot-3.svg");
            &.tns-nav-active {
              @include background("../../Assets/img/Dot-fill-3.svg");
            }
          }
          &:nth-child(5n - 1) {
            @include background("../../Assets/img/Dot-4.svg");
            &.tns-nav-active {
              @include background("../../Assets/img/Dot-fill-4.svg");
            }
          }
          &:nth-child(5n) {
            @include background("../../Assets/img/Dot-5.svg");
            &.tns-nav-active {
              @include background("../../Assets/img/Dot-fill-5.svg");
            }
          }
        }
      }
      .tns-inner {
        .tns-outer {
          .tns-nav {
            display: none !important;
          }
        }
      }
    }
    .for-desc {
      padding-left: 120px;
      width: calc(100% - 696px);
      @include ultra-desktop-below {
        width: calc(100% - 640px);
      }
      @include media-1299-below {
        padding-left: 108px;
        width: calc(100% - 600px);
      }
      @include desktop {
        padding-left: 50px;
        width: calc(100% - 520px);
      }
      @include tablet {
        padding-left: 24px;
        width: calc(100% - 50%);
      }
      @include ultra-mobile {
        padding-left: 0;
        width: 100%;
      }
      @include ultra-mobile {
        padding-top: 20px;
      }
      .desc-wrap {
        max-width: 462px;
        @include ultra-mobile {
          max-width: 100%;
        }
      }
      .product-name {
        h3 {
          color: $color1;
        }
      }
      .product-info {
        @include flex-start-center;
        margin-top: 16px;
        .member-label {
          @include flex-center-center;
          margin-left: 8px;
          @include prefix(border-radius, 1px);
          background-color: $color5;
          padding: 4px 8px;
          span {
            @include fonts("Inter", $color8, 12px, 400, 14px, 0);
          }
          img {
            margin-left: 4px;
            max-width: 12px;
          }
        }
        .price-wrap {
          p {
            font-size: 30px;
            line-height: 26px;
            font-weight: 700;
            @include mobile{
              font-size: 24px;
            }
            // @include flex-start-end;
            // &::first-letter {
            //   @include fonts("Work Sans", $color8, 24px, 700, 26px, 0);
            // }
          }
        }
      }
      .about-prod {
        margin-top: 24px;
        @include desktop {
          margin-top: 20px;
        }
        @include ultra-mobile {
          margin-top: 24px;
        }
        ul {
          margin-top: 16px;
          li {
            @include fonts("Inter", $color8, 16px, Normal, 30px, 0);
            padding-left: 18px;
            position: relative;
            span {
              font-weight: 600;
              display: inline-block;
            }
            &:nth-child(5n - 4) {
              &::after {
                position: absolute;
                content: "";
                top: 12px;
                left: 0;
                @include hw(7px);
                @include background("../Assets/img/Dot-fill-1.svg");
              }
            }
            &:nth-child(5n - 3) {
              &::after {
                position: absolute;
                content: "";
                top: 12px;
                left: 0;
                @include hw(7px);
                @include background("../Assets/img/Dot-fill-2.svg");
              }
            }
            &:nth-child(5n - 2) {
              &::after {
                position: absolute;
                content: "";
                top: 12px;
                left: 0;
                @include hw(7px);
                @include background("../Assets/img/Dot-fill-3.svg");
              }
            }
            &:nth-child(5n - 1) {
              &::after {
                position: absolute;
                content: "";
                top: 12px;
                left: 0;
                @include hw(7px);
                @include background("../Assets/img/Dot-fill-4.svg");
              }
            }
            &:nth-child(5n) {
              &::after {
                position: absolute;
                content: "";
                top: 12px;
                left: 0;
                @include hw(7px);
                @include background("../Assets/img/Dot-fill-5.svg");
              }
            }
          }
        }
      }
      .product-action {
        margin-top: 48px;
        @include desktop {
          margin-top: 32px;
        }
        @include ultra-mobile {
          margin-top: 40px;
        }
        @include flex-between-start;
        .size-dropdown {
          width: calc(100% - 120px);
          @include ultra-mobile {
            width: calc(100% - 115px);
          }
          select {
            height: 32px;
            @include fonts("Inter", $color1, 16px, 400, 24px, 0);
            @include ultra-mobile {
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
        .inc-decbox {
          width: 120px;
          padding-left: 16px;
          @include mobile {
            width: 115px;
            padding-left: 19px;
          }
          .inc-dec-box {
            width: 100%;
            padding-bottom: 7px;
            justify-content: space-between;
            input {
              @include fonts("Inter", $color1, 16px, 400, 24px, 0);
            }
          }
        }
      }
      .action-bar {
        margin-top: 48px;
        @include desktop {
          margin-top: 32px;
        }
        .MuiButton-contained.fill-btn {
          width: 100%;
        }
      }
      .add-to-cart {
        color: red;
      }
    }
  }
  .product-points {
    background-color: #efe3d3;
    text-align: center;
    padding: 72px 0 104px 0;
    @include mobile{
      display: none;
    }
    .in-point {
      max-width: 1136px;
      margin: 0 auto;
    }
    @include ultra-mobile {
      padding: 32px 0 36px 0;
    }
    .point-wrap {
      .for-img {
        img {
          margin: 0 auto;
          max-width: 104px;
          @include ultra-mobile {
            max-width: 71px;
          }
        }
      }
      .for-desc {
        margin-top: 8px;
        h4 {
          text-transform: uppercase;
          max-width: 224px;
          margin: 0 auto;
          @include tablet {
            max-width: 212px;
          }
          @include ultra-mobile {
            @include fonts("Work Sans", $color8, 12px, 500, 18px, 0);
            max-width: 148px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

.detail-tables {
  padding-bottom: 44px;
  @include mobile-above{
    display: none;
  }
  table {
    width: 100%;
    tr {
      th {
        background: #d6d1ca;
        padding: 6px 12px;
        @include fonts("Inter", #333333, 14px, 500, 16px, 0);
        &:nth-child(2) {
          text-align: center;
        }
        &:last-child {
          text-align: right;
        }
      }
      td {
        background: rgba(214, 209, 202, 0.4);
        padding: 16px 12px;
        @include fonts("Inter", #333333, 14px, 400, 16px, 0);
        white-space: nowrap;
        border-bottom: 1px solid #d6d1ca;
        &:first-child {
          font-weight: 600;
          color: #4e738a;
        }
        &:nth-child(2) {
          text-align: center;
        }
        &:last-child {
          text-align: right;
        }
      }
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
}

.take-look-section {
  padding-top: 56px;
  padding-bottom: 104px;
  @include mobile-above{
    display: none;
  }
  &.grey-bg{
    background-color: #F4F2F1;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      top: 36px;
      left: 0;
      width: 124px;
      height: 155px;
      background-image: url(../../Assets/img/Shape-grey.svg);
      background-size: cover;
      background-position: center;
    }
  }
  &.red-bg {
    background-color: #ffb98e;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      top: 36px;
      left: 0;
      width: 124px;
      height: 155px;
      background-image: url(../../Assets/img/Shape-red.svg);
      background-size: cover;
      background-position: center;
    }
  }
  &.blue-bg {
    background-color: #96daea;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      top: 36px;
      left: 0;
      width: 124px;
      height: 155px;
      background-image: url(../../Assets/img/Shape-blue.svg);
      background-size: cover;
      background-position: center;
    }
  }
  &.green-bg {
    background-color: #cedfd7;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      top: 16px;
      right: 0;
      width: 127px;
      height: 155px;
      background-image: url(../../Assets/img/Shape-green.svg);
      background-size: cover;
      background-position: center;
    }
  }
  &.yellow-bg {
    background-color: #fdd756;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      top: 16px;
      right: 0;
      width: 127px;
      height: 155px;
      background-image: url(../../Assets/img/Shape-yellow.svg);
      background-size: cover;
      background-position: center;
    }
  }
  h4 {
    @include fonts("Inter", #000000, 24px, 600, 32px, 0);
    margin-bottom: 32px;
    position: relative;
    z-index: 1;
  }
  .img-content {
    margin-bottom: 32px;
    position: relative;
    z-index: 1;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      width: 100%;
      height: auto;
      margin-bottom: 24px;
    }
    p {
      @include fonts("Inter", #000000, 18px, 400, 28px, 0);
      text-align: center;
    }
  }
}
