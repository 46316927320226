.guest-form {
    margin-top: 24px;
    .action-bar {
        text-align: center;
        margin-top: 16px;
        .MuiButton-outlined.border-btn {
          width: 100%;
        }
        @include ultra-mobile {
          order: 4;
          margin-top: 46px;
        }
        .login-action {
          @include fonts("Inter", $color13, 14px, 400, 20px, 0);
          .login-link {
            margin-left: 4px;
            @include fonts("Inter", $color1, 14px, 600, 20px, 0);
            border-bottom: 1px solid transparent;
            &:hover {
              cursor: pointer;
              border-bottom: 1px solid $color1;
            }
          }
        }
        .signup-action {
          margin-top: 12px;
          @include fonts("Inter", $color13, 14px, 400, 20px, 0);
          .signup-link {
            margin-left: 4px;
            @include fonts("Inter", $color1, 14px, 600, 20px, 0);
            border-bottom: 1px solid transparent;
            &:hover {
              cursor: pointer;
              border-bottom: 1px solid $color1;
            }
          }
        }
      }
}