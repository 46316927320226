.personaldata-wrap {
  .page-content {
    margin-top: 32px;
    .upper-wrap {
      position: relative;
      max-width: 100%;
      margin-bottom: 32px;
      .input-groups,
      .pwd-wrap {
        max-width: 100%;
      }
      @include mobile {
        margin-bottom: 16px;
        max-width: 100%;
        input {
          background: transparent;
        }
      }
      @include tablet {
        max-width: 100%;
        .input-groups {
          max-width: 100%;
          input {
            background: transparent;
          }
        }
      }
      .pdata-modal {
        @include flex-between-center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
        cursor: pointer;
        &:after {
          content: " ";
          @include hw(22px);
          background-image: url(../../../Assets/img/sidebar-emptynav.svg);
          display: inline-block;
        }
      }
      .input-groups.password {
        .pwd-wrap {
          .visibilitybtn {
            display: none;
          }
        }
      }
    }
  }
  .input-groups input{
    height: 56px;
    padding-top: 12px;
    text-transform: capitalize;
  }
  .input-groups .active{
    top: 0;
  }
}
