.side-menu {
  position: fixed;
  width: 360px;
  background: $color1;
  top: 0px;
  height: 100vh;
  left: -360px;
  transition: all 300ms ease;
  z-index: 999;
  @include iphone{
    width: 100%;
    left: -100%;
  }
  &.active {
    left: 0;
    transition: all 300ms ease;
  }
  .side-menu-header{
    @include flex-between-center;
    padding: 40px 28px 20px;
    position: relative;
    .close-icon{
      @include hw(27px);
    }
    .mobile-logo{
      position: absolute;
      display: block;
      top: 40px;
      left: 50%;
      @include prefix(transform, translateX(-50%));
      img{
        height: 30.7px;
        width: auto;
      }
    }
   .add-to-cart {
      position: relative;
      cursor: pointer;
      @include mobile-above{
        display: none;
      }
      .dropdown-toggle{
        padding: 0;
        background-color: transparent !important;
        outline: none !important;
        border: 0 !important;
        box-shadow: none !important;
        &::after{
          display: none;
        }
        p {
          position: absolute;
          font-family: "Quicksand";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: $black;
          left: 50%;
          top: 4px;
          @include prefix(transform, translateX(-50%));
        }
      }
    }
  }
  .scroll-side-menu{
    height: calc(100% - 288px);
    overflow-y: auto;
    padding: 22px 28px 12px;
    // text-transform: uppercase;
    .side-menu-list{
      li {
        @include prefix(transition, all 300ms ease-in);
        .nav-links {
          display: block;
          @include fonts("Inter", $white, 18px, 500, 32px, 0);
          @include prefix(transition, all 300ms ease-in);
        }
        margin-bottom: 14px;
        &:last-child {
          margin-bottom: 0;
        }
        &.active,
        &:hover {
          cursor: pointer;
          .nav-links {
            color: $color5;
          }
        }
      }
    }
  }
  .side-menu-footer{
    padding: 20px 28px 45px;
    .fill-btn{
      margin-bottom: 8px;
      background-color: $color5;
      @include fonts('Work Sans', $color8, 14px, 500, 16px, 0.02em);
      width: 100%;
      &:hover {
        background-color: $color15;
      }
    }
    .border-btn{
      border: 2px solid $white !important;
      filter: drop-shadow(0px 0px 64px rgba(0, 0, 0, 0.08));
      box-shadow: none;
      width: 100%;
      @include fonts('Work Sans', $white, 14px, 500, 16px, 0.02em);
    }
  }
}




.side-back {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background: $black;
  opacity: 0.2;
  height: 100vh;
  z-index: 998;
  display: none;
  &.active {
    display: block;
  }
}
