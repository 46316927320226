.login-popup {
  padding-left: 0 !important;
  .modal-dialog {
    max-width: 360px;
    @include ultra-mobile {
      margin-left: auto;
      margin-right: auto;
    }
    @include mini-mobile{
      margin: 0;
    }
    .modal-content {
      // max-height: 640px;
      background-color: $white;
      border: 0;
      @include prefix(border-radius, 0);
    }
    .modal-header {
      display: block;
      position: relative;
      border-bottom: 0;
      padding: 40px 28px 20px 28px;
      h5 {
        text-align: center;
        @include fonts("Work Sans", $color1, 24px, 600, 26px, 0);
      }
      .btn-close {
        padding: 0;
        background: url("../../Assets/img/modal-close.svg");
        background-repeat: no-repeat;
        @include hw(28px);
        margin: 0;
        opacity: 1;
        position: absolute;
        left: 28px;
        top: 40px;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .modal-body {
      padding: 20px 28px 50px 28px;
      // overflow-y: auto;
      // max-height: calc(100% - 76px);
      
      .forgot-links{
        text-align: left;
        margin-top: 20px;
        .pages-link{
          font-size: 12px;
        line-height: 16px;
        }
      }
      .fill-btn{
        margin-top: 32px;
        width: 100%;
      }
      .or{
        text-align: center;
        margin-top: 16px;
        margin-bottom: 16px;
        position: relative;
        @include fonts("Inter", $color8, 14px, 500, 20px, 0);
        &::before{
          position: absolute;
          content: '';
          height: 1px;
          background-color: $color4;
          left: 0;
          width: calc(50% - 22px);
          top: 50%;
          @include prefix(transfrom, translateY(-50%));
        }
        &::after{
          position: absolute;
          content: '';
          height: 1px;
          background-color: $color4;
          right: 0;
          width: calc(50% - 22px);
          top: 50%;
          @include prefix(transfrom, translateY(-50%));
        }
      }
      .social-login{
        > div{
          > div{
            iframe{
              margin: 0 auto !important;
            }
          }
        }
        .social-btns{
          width: 100%;
          cursor: pointer;
          display: block;
          margin-bottom: 8px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .have-account{
        margin-top: 46px;
        @include fonts("Inter", $color13, 14px, 400, 20px, 0);
        text-align: center;
        a{
          color: $color1;
          font-weight: bold;
          border-bottom: 1px solid transparent;
          cursor: pointer;
          &:hover{
            border-color: $color13;
          }
        }
      }
    }
  }
}
