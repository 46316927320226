.login-form {
  margin-top: 24px;
  @include prefix(display, flex);
  @include prefix(flex-direction, column);
  @include ultra-mobile {
    margin-top: 32px;
  }
  .forg-pwdwrap {
    .forgot-pwd {
      @include fonts("Inter", $color8, 12px, 400, 16px, 0);
      border-bottom: 1px solid $color8;
    }
  }
  .form-fieldset {
    @include ultra-mobile {
      order: 3;
    }
  }
  .MuiButton-contained.fill-btn {
    width: 100%;
    box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.11);
    margin-top: 32px;
    @include ultra-mobile {
      margin-top: 26px;
    }
  }
  .button-bar {
    @include flex-center-center;
    @include ultra-mobile {
      order: 1;
    }
    .socialloginbtn {
      width: calc(50% - 8px);
    }
  }
  .action-bar {
    text-align: center;
    margin-top: 16px;
    .MuiButton-outlined.border-btn {
      width: 100%;
    }
    @include ultra-mobile {
      order: 4;
      margin-top: 46px;
    }
    .custom-action {
      @include fonts("Inter", $color13, 14px, 400, 20px, 0);
      .signup-link {
        margin-left: 4px;
        @include fonts("Inter", $color1, 14px, 600, 20px, 0);
        border-bottom: 1px solid transparent;
        &:hover {
          cursor: pointer;
          border-bottom: 1px solid $color1;
        }
      }
    }
    .guest-action {
      margin-top: 12px;
      @include fonts("Inter", $color13, 14px, 400, 20px, 0);
      .as-guest-link {
        margin-left: 4px;
        @include fonts("Inter", $color1, 14px, 600, 20px, 0);
        border-bottom: 1px solid transparent;
        &:hover {
          cursor: pointer;
          border-bottom: 1px solid $color1;
        }
      }
    }
  }
  
  .seprator {
    margin: 16px 0;
    @include fonts("Inter", $color8, 14px, 500, 20px, 0);
    text-align: center;
    @include ultra-mobile {
      order: 2;
      height: 20px;
      text-align: center;
      position: relative;
      background-color: $white;
      &::before {
        content: " ";
        display: block;
        height: 1px;
        background: $color4;
        position: absolute;
        top: 50%;
        width: 100%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 1;
      }
    }
    span {
      @include ultra-mobile {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        padding: 0 15px;
        background-color: $white;
      }
    }
  }
}
