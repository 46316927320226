.product-listing{
  .product-listing-wrapper{
    padding-top: 104px;
    padding-bottom: 24px;
    max-width: 1134px;
    margin-left: auto;
    margin-right: auto;
    @include ultra-mobile{
      padding-top: 0;
      padding-bottom: 1px;
      margin-top: -167px;
    }
    .row{
      margin-left: -27px;
      margin-right: -27px;
      & > *{
        padding-left: 27px;
        padding-right: 27px;
      }
      @include ultra-mobile{
        margin-left: -12px;
        margin-right: -12px;
        & > *{
          padding-left: 12px;
          padding-right: 12px;
        }
      }
    }
    .listing-box{
      width: 100%;
      display: block;
      margin-bottom: 80px;
      text-decoration: none;
      cursor: pointer;
      @include ultra-mobile{
        margin-bottom: 37px;
      }
      .listing-img-wrapper{
        display: block;
        img{
          width: 100%;
          height: auto;
        }
      }
      .listing-content{
        padding-top: 24px;
        h4{
          color: $color1;
          margin-bottom: 2px;
          text-transform: uppercase
        }
        p{
          @include fonts("Inter", $color8, 14px, Normal, 22px, 0);
        }
        span{
          @include fonts("Inter", $color13, 14px, Normal, 22px, 0);
        }
      }
    }
  }
}

.product-listing-mobile-header{
  background: #D6D1CA;
  padding-top: 70px;
  padding-bottom: 231px;
  @include ultra-mobile-above{
    display: none;
  }
  h1{
    @include fonts("Work Sans", #000000, 32px, 600, 36px, 0);
    margin-bottom: 32px;
  }
  p{
    @include fonts("Inter", #000000, 16px, 400, 28px, 0);
  }
}