.orders-wrap {
	.page-content {
		margin-top: 32px;
		.accordion {
			.accordion-item {
				position: relative;
				border: 0;
				border-bottom: 1px solid $color4;
				border-radius: 0;
				&:first-of-type {
					.accordion-button {
						padding-top: 0 !important;
					}
					.order-actions {
						margin-left: auto;
						&.desk {
							position: absolute;
							top: 4px;
							right: 43px;
						}
					}
				}
				@include tablet {
					background-color: transparent;
				}
				.accordion-button {
					padding: 16px 0 16px 0;
					@include prefix(justify-content, space-between);
					@include tablet {
						background-color: $color2;
					}
					&::after {
						background-image: url(../../../Assets/img/accr-plus.svg);
						background-position: center;
						background-size: 12px;
						margin-left: 32px;
						@include tablet {
							border-radius: 50%;
							background-color: $white;
						}
					}
					&:not(.collapsed) {
						font-weight: 600;
						background-color: transparent;
						box-shadow: none;
						&::after {
							background-image: url(../../../Assets/img/accr-minus.svg);
							transform: rotate(0deg);
						}
						@include tablet {
							background-color: $color2;
						}
					}
					&:focus {
						background-color: transparent;
						box-shadow: none;
					}
					&:hover {
						z-index: 0;
					}
					.order-info {
						.order-id {
							@include fonts("Inter", $color8, 16px, 600, 28px, 0);
						}
						.placed-date {
							font-weight: 400;
						}
					}
				}
				.accordion-body {
					padding: 0 0 16px 0;
					table {
						width: 100%;
						.col {
							&.two,
							&.three {
								text-align: center;
							}
							&.one {
								padding-left: 16px;
							}
							&.four {
								padding-right: 18px;
								text-align: right;
								@include ultra-mobile {
									padding-right: 16px;
								}
							}
							@include ultra-mobile {
								&.two {
									display: none;
								}
							}
						}
						thead {
							.col {
								@include fonts("Inter", $color8, 12px, 500, 16px, 0);
								padding: 6px 0;
								background-color: $color4;
							}
						}
						tbody {
							background-color: $color11;
							@include tablet {
								background-color: $white;
							}
							.col {
								border-bottom: 1px solid $color4;
								padding-top: 20px;
								padding-bottom: 16px;
								&.two,
								&.three,
								&.four {
									vertical-align: top;
									@include fonts("Inter", $color8, 12px, 600, 16px, 0);
								}
								.product-nameinfo {
									.p-name {
										@include fonts("Inter", $color8, 12px, 600, 16px, 0);
										margin-bottom: 4px;
										@include ultra-mobile {
											margin-bottom: 2px;
										}
									}
									.p-cat {
										@include fonts("Inter", $color1, 12px, 400, 16px, 0);
									}
								}
								&.ft {
									border-bottom: 0;
									padding: 6px 16px 6px 18px;
								}
							}
							.tfooter {
								.col {
									border-bottom: 0;
									padding-top: 6px;
									padding-bottom: 6px;
									@include fonts("Inter", $color8, 12px, 600, 16px, 0);
									&.st {
										padding-top: 14px;
									}
									&.dc {
										padding-bottom: 16px;
										color: $color12;
										border-bottom: 1px solid $color4;
									}
									&.gt {
										padding-top: 16px;
										padding-bottom: 24px;
										@include fonts("Inter", $color8, 16px, 600, 16px, 0);
										span {
											font-weight: 400;
											font-size: 12px;
											text-transform: capitalize;
										}
									}
								}
							}
						}
					}
				}
				.order-actions {
					margin-left: auto;
					&.mobile {
						@include tablet {
							@include flex-between-center;
							margin-top: 16px;
						}
					}
					&.desk {
						position: absolute;
						top: 19px;
						right: 40px;
					}
					.MuiButton-outlined.border-btn {
						border-width: 1px;
						width: 119px;
						padding: 0;
						height: 36px;
						font-size: 12px;
						margin-right: 6px;
            &:hover{
              border: 1px solid $color14;
            }
						@include ultra-mobile {
							width: calc(50% - 4px);
							margin-right: 0;
						}
					}
				}
			}
		}
	}
	.no-orderwrap {
		padding: 140px 28px;
		@include flex-center-center;
		@include prefix (flex-direction, column);
		img {
			@include hw(82px);
		}
		label {
			margin-top: 16px;
			@include fonts("Inter", $color8, 16px, 600, 28px, 0);
		}
	}
}
