.web-account-wrapper {
	padding-top: 24px;
	padding-bottom: 100px;
	@include tablet {
		display: none;
	}
	.container {
		@include above-1400 {
			max-width: 1184px;
			padding: 0;
		}
	}
	.web-account-inner {
		@include flex-start-start;
		margin-top: 14px;
		@include tablet {
			@include prefix(flex-direction, column);
		}
		.right-desc {
			width: calc(100% - 360px);
			padding-left: 56px;
			@include desktop{
				padding-left: 32px;
			}
			@include tablet {
				width: 100%;
				padding-left: 0;
				padding-top: 24px;
				padding-bottom: 0;
			}
			.page-title {
				h5 {
					@include fonts("Work Sans", $black, 24px, 600, 26px, 0);
				}
			}
		}
	}
}



.address-popup, .pdataname-popup, .pdatapwd-popup {
  .modal-content {
    border: 0;
    @include prefix (border-radius, 0);
  }
}