.cart-items {
	background-color: $color2;
	padding: 22px 28px 54px 28px;
	@include tablet {
		padding: 16px 20px 36px 20px;
	}
	.f2036 {
		color: $color1;
		margin-bottom: 20px;
	}
	.bag-content {
		.cart-box {
			@include flex-start-start;
			@include prefix(flex-direction, column);
			// max-height: 220px;
			// @include prefix(overflow-y, scroll);
			// @include prefix(overflow-x, hidden);
			.item {
				width: 100%;
				@include flex-start-start;
				border-bottom: 1px solid $color4;
				&:not(:last-child) {
					padding-bottom: 12px;
					margin-bottom: 18px;
				}
				&:last-child {
					padding-bottom: 34px;
				}
				.cart-data-img {
					width: 84px;
				}
				.cart-data {
					width: calc(100% - 84px);
					padding-left: 16px;
					padding-top: 2px;
					@include tablet {
						padding-left: 12px;
					}
					.f1626 {
						margin-bottom: 4px;
					}
					.atrributes {
						margin-bottom: 12px;
						.select-group {
							select {
								height: 24px;
								padding: 0 20px 0 4px;
								background-position: calc(100% - 4px), calc(100% - 4px);
								@include fonts("Inter", $color1, 12px, 400, 18px, 0);
							}
						}
						.with-incdec {
							@include flex-between-center;
							.inc-dec-box {
								width: calc(100% - 144px);
								@include desktop {
									width: calc(100% - 108px);
								}
								@include tablet {
									width: calc(100% - 96px);
									button {
										img {
											@include hw(10px);
										}
									}
									input {
										min-width: 16px;
										max-width: 16px;
									}
								}
							}
							.select-group {
								max-width: 136px;
								@include desktop {
									max-width: 96px;
								}
								@include tablet {
									max-width: 88px;
								}
							}
						}
						.inc-dec-box {
							input {
								background-color: transparent;
							}
						}
					}
					.price-action {
						@include flex-between-center;
						.price-text {
							&:first-letter {
								@include fonts("Work Sans", $color8, 14px, 500, 26px, 0);
							}
						}
					}
				}
			}
		}
		.shipping-section {
			@include flex-between-center;
			padding: 12px 0;
			border-bottom: 1px solid $color4;
			@include fonts("Inter", $color8, 14px, 600, 26px, 0);
		}
		.total-section {
			@include flex-between-center;
			padding: 16px 0 32px 0;
			.label-text {
				@include fonts("Inter", $color8, 18px, 600, 26px, 0);
			}
			.price-text {
				&:first-letter {
					@include fonts("Work Sans", $color8, 14px, 500, 26px, 0);
				}
			}
		}
		.action-bar {
			.MuiButton-contained.fill-btn {
				width: 100%;
			}
		}
	}
}
