.account-menu-wrapper {
  background-color: $color2;
  @include prefix(border-radius, 2px);
  padding: 40px 32px 32px;
  width: 360px;
  @include tablet {
    width: 100%;
  }
  @include mobile {
    padding: 20px 0 0 0;
  }
  .personal-info {
    @include flex-start-center;
    .profile-img {
      @include hw(56px);
      img{
        border-radius: 100%;
      }
    }
    .profile-text {
      padding-left: 16px;
      width: calc(100% - 56px);
      h3{
        text-transform: capitalize;
      }
      .email {
        margin-top: 6px;
        @include fonts("Inter", $color8, 14px, 400, 20px, 0);
      }
    }
  }
  .acc-menu {
    margin-top: 30px;
    h5 {
      @include fonts("Work Sans", $black, 24px, 600, 26px, 0);
      margin-bottom: 24px;
      margin-top: 32px;
      @include tablet {
        margin-bottom: 32px;
        margin-top: 56px;
      }
    }
  }
  .acc-menu,
  .help-menu {
    li {
      @include tablet {
        border-bottom: 1px solid $color4;
        padding-bottom: 16px;
        margin-bottom: 16px;
      }
      .sidebar-links {
        @include fonts("Inter", $color8, 16px, 400, 28px, 0);
        @include flex-between-center;
        @include tablet {
          font-weight: 600;
        }
        &:after {
          content: " ";
          @include hw(22px);
          background-image: url(../../../Assets/img/sidebar-emptynav.svg);
        }
      }
      &.active {
        .sidebar-links {
          @include tablet-above {
            font-weight: 600;
            &:after {
              background-image: url(../../../Assets/img/sidebar-fillnav.svg);
            }
          }
        }
      }
      @include tablet-above {
        &:not(:first-child) {
          margin-top: 8px;
        }
      }
    }
  }
  .sidebar-action {
    margin-top: 24px;
    @include tablet {
      margin-top: 48px;
    }
    .MuiButton-contained.fill-btn {
      width: 100%;
      @include mobile {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
