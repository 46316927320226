.loader{
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255,255,255,0.6);
  z-index: 9999;
  @include flex-center-center;
  @include prefix(flex-direction, column);
  img {
    width: 75px;
    height: 75px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  p{
    margin-top: 29px;
    @include fonts("Inter", $color8, 20px, 600, 28px, 0);
  }
  span{
    display: block;
    @include fonts("Inter", $color8, 16px, 400, 28px, 0);
  }
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}