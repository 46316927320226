.rd {
	@include prefix(display, flex);
	align-items: center;
	input[type="radio"] {
		background: url(../../../Assets/img/radio-default.svg);
		cursor: pointer;
		background-repeat: no-repeat;
		height: 23px;
		content: "";
		width: 22px;
		-webkit-appearance: initial;
		display: inline-block;
		margin: 0;
	}
	input[type="radio"]:checked {
		background: url(../../../Assets/img/radio-checked.svg);
		cursor: pointer;
		background-repeat: no-repeat;
		height: 23px;
		content: "";
		width: 22px;
		-webkit-appearance: initial;
		display: inline-block;
		margin: 0;
	}
	input[type="radio"]:not([disabled]):focus {
		box-shadow: none;
	}
	input[type="radio"]:hover:not([disabled]):focus {
		box-shadow: none;
	}
	label {
		@include fonts("Inter", $color8, 16px, normal, 28px, 0);
		padding-left: 12px;
	}
}
