.subscription-popup {
  .modal-dialog {
    // min-height: auto;
    max-width: 370px;
    @include mobile {
      max-width: 100%;
      height: 100%;
      margin: 0;
    }
    @include ultra-mobile {
      margin: 0 auto;
      height: 100%;
    }
    .modal-content {
      background-color: $color2;
      border: 0;
      @include prefix(border-radius, 0);
      @include mobile {
        height: 100%;
      }
      .modal-header {
        padding: 32px 28px;
        border-bottom: 0;
        @include prefix(justify-content, end);
        @include mobile {
          @include prefix(justify-content, start);
          padding: 32px 28px;
        }
        @include tablet {
          padding: 24px 28px;
        }
        .btn-close {
          background: url("../../../Assets/img/modal-close.svg");
          background-repeat: no-repeat;
          @include hw(28px);
          padding: 0;
          margin: 0;
          opacity: 1;
          &:focus {
            box-shadow: none;
          }
        }
      }
      .modal-body {
        padding: 22px 28px 113px 28px;
        @include tablet {
          padding: 16px 20px 88px 20px;
        }
        @include mobile {
          padding: 20px 28px 0 28px;
        }
        .m-head {
          @include fonts("Inter", $black, 18px, 600, 26px, 0);
          @include mobile {
            @include fonts("Inter", $black, 20px, 600, 28px, 0);
          }
        }
        .m-desc {
          margin-top: 12px;
          @include fonts("Inter", $color8, 16px, 400, 28px, 0);
          @include mobile {
            margin-top: 8px;
          }
        }
        .cpoint-details {
          width: 100%;
          margin-top: 24px;
          @include flex-between-center;
          @include mobile {
            flex-direction: row;
          }
          .point {
            padding: 24px 0;
            width: 50%;
            max-width: calc(50% - 4px);
            background: #ffffff;
            @include prefix(border-radius, 4px);
            text-align: center;
            @include mobile {
              flex-direction: column;
              max-width: calc(50% - 4px);
            }
            .time {
              margin-bottom: 8px;
              min-height: 50px;
              display: flex;
              align-items: center;

              p {
                @include fonts("Work Sans", $color8, 18px, 400, 24px, 0);
              }
            }
            .amount {
              .price-text {
                font-size: 32px;
                line-height: 38px;
                color: $color1;
                &:first-letter {
                  @include fonts("Work Sans", $color1, 32px, 500, 37.65px, 0);
                }
              }
            }
            border: 1.5px solid transparent;
            &.active {
              border: 1.5px solid $color5;
              position: relative;
              &:before {
                content: " ";
                background-image: url(../../../Assets/img/orange-tick.svg);
                @include hw(12px);
                position: absolute;
                top: 8px;
                left: 8px;
              }
            }
            &:hover {
              border: 1.5px solid $color5;
              cursor: pointer;
            }
          }
        }
        .action-bar {
          margin-top: 32px;
          .MuiButton-contained.fill-btn {
            max-width: 304px;
            width: 100%;
            @include mobile {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
