.communication-settings{
  .page-content{
    margin-top: 32px;
    .CS-box{
      @include flex-between-center;
      border-bottom: 1px solid $color4;
      padding: 16px 0;
      &:first-child{
        padding-top: 0;
      }
      &:last-child{
        border-bottom: 0;
      }
      p{
        padding-right: 28px;
        width: calc(100% - 38px);
        @include fonts("Inter", $color8, 14px, 400, 24px, 0)
      }
      .button {
        position: relative;
        top: 50%;
        width: 38px;
        height: 20px;
        overflow: hidden;
        border-radius: 100px;
        .knobs, .layer {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 100px;
        }
        .checkbox {
          position: relative;
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 0;
          opacity: 0;
          cursor: pointer;
          z-index: 3;
        }
        .knobs {
          z-index: 2;
        }
        .layer {
          width: 100%;
          background: $color4;
          opacity: 0.5;
          transition: 0.3s ease all;
          z-index: 1;
        }
        .knobs:before {
          content: '';
          position: absolute;
          top: 2px;
          left: 2px;
          width: 16px;
          height: 16px;
          background-color: #FFF;
          border-radius: 50%;
          transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
        }
        .checkbox:checked + .knobs:before {
          content: '';
          left: 20px;
        }
        .checkbox:checked ~ .layer {
          opacity: 1;
          background: $color1;
        }
        .knobs,
        .knobs:before,
        .layer {
          transition: 0.3s ease all;
        }
      }
    }
  }
}