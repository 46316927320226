.overlay-summary {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  &.hide {
    .summary-top {
      display: none;
    }
  }
  &.show {
    background-color: $color2;
    height: 100vh;
    @include prefix(display, flex);
    @include prefix(flex-direction, column);
    .summary-top {
      padding: 32px 28px 30px 28px;
      @include flex-between-center;
      p {
        @include fonts("Work Sans", $black, 24px, 600, 26px, 0);
      }
      .closebtn {
        @include background("../../../Assets/img/cksummary-close.svg");
        @include hw(26px);
        border: 0;
        @include prefix (box-shadow, none);
      }
    }
  }
  .cart-items {
    @include prefix(display, flex);
    @include prefix(flex-direction, column);
    &.hide {
      display: none;
    }
    &.show {
      flex: 1 1;
      overflow-y: auto;
    }
    .price-text {
      &:first-letter {
        @include fonts("Work Sans", $color8, 14px, 500, 26px, 0);
      }
    }
    .bag-content {
      .cart-box {
        .item {
          @include prefix (align-items, end);
          &:not(:last-child) {
            margin-bottom: 12px;
            padding-bottom: 12px;
          }
          &:last-child {
            padding-bottom: 12px;
          }
          .cart-data-img {
            width: 65px;
            height: 56px;
          }
          .cart-data {
           padding-left: 19px;
           padding-top: 0;
           .f1626 {
            @include fonts("Inter", $color8, 14px, 600, 26px, 0);
            margin-bottom: 0;
            display: block;
            display: -webkit-box;
            max-width: 100%;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
           }
           .with-category, .with-sub {
              p {
                @include fonts("Inter", $color1, 12px, 400, 24px, 0);
              }
           }
          }
          .price-action {
            padding-left: 12px;
          }
        }
      }
      .shipping-section {
        .label-text {
          @include fonts("Inter", $color8, 14px, 600, 26px, 0);
        }
      }
    }
  }
  .total-section {
    background-color: $color2;
    padding: 10px 28px 16px 28px;
    @include prefix (display, flex);
    @include prefix (flex-wrap, wrap);
    @include prefix (align-items, center);
    .btnoverlay {
      @include background("../../../Assets/img/sidebar-emptynav.svg");
      border: 0;
      @include hw(22px);
      @include prefix (box-shadow, none);
      &.hide {
        @include prefix(transform ,rotate(270deg));
        transition: all .3s linear;
      }
      &.show {
        @include prefix(transform ,rotate(90deg));
        transition: all .3s linear;
      }
    }
    .label-text {
      @include fonts("Inter", $color8, 18px, 600, 26px, 0);
    }
    .price-text {
      margin-left: auto;
      margin-right: 7px;
      &:first-letter {
        @include fonts("Work Sans", $color8, 14px, 500, 26px, 0);
      }
    }
    .action-bar {
      @include prefix (flex-basis, 100%);
      margin-top: 12px;
      .MuiButton-contained.fill-btn {
        width: 100%;
      }
    }
  }
}
