.ckaddress-popup {
  padding-left: 0 !important;
  .modal-dialog {
    max-width: 360px;
    // min-height: auto;
    @include ultra-mobile {
      max-width: 100%;
      margin: 0;
    }
    .modal-content {
      max-height: 640px;
      border: 0;
      background-color: $color2;
      @include prefix(border-radius, 0);
    }
    .modal-header {
      justify-content: space-between;
      border-bottom: 0;
      padding: 32px 28px 24px 28px;
      h5 {
        text-align: center;
        @include fonts("Work Sans", $color1, 24px, 600, 26px, 0);
      }
      .btn-close {
        padding: 0;
        background: url("../../../../Assets/img/modal-close.svg");
        background-repeat: no-repeat;
        @include hw(28px);
        margin: 0;
        opacity: 1;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .modal-body {
      padding: 0 28px 32px 28px;
      overflow-y: auto;
      max-height: calc(100% - 76px);
      .ckaddress-form {
        .sub-section {
          padding-top: 36px;
          h4 {
            line-height: 26px;
            color: $color1;
            margin-bottom: 24px;
          }
        }
        .input-groups,
        .select-group {
          margin-bottom: 20px;
          input {
            background: transparent;
          }
          select {
            @include fonts("Inter", $black, 16px, 400, 28px, 0);
            height: 30px;
          }
        }
        .half {
          @include flex-between-center;
          .input-groups {
            width: calc(50% - 4px);
            input {
              width: 100%;
            }
          }
        }
        .phone {
          @include prefix(flex-direction, row);
          @include prefix(justify-content, space-between);
          select {
            max-width: 64px;
            height: 46px;
            padding: 0 4px 0 6px;
            background-position: calc(100% - 4px), calc(100% - 8px);
            @include fonts("Inter", $color8, 14px, 500, 24px, 0);
          }
          .input-groups {
            width: calc(100% - 72px);
            margin-bottom: 0;
          }
        }
        .same-as {
          padding: 8px 0 16px 0;
        }
      }
      .action-bar {
        padding-top: 8px;
        .MuiButton-contained.fill-btn {
          width: 100%;
        }
      }
    }
  }
}
