.address-wrap {
  .page-content {
    margin-top: 12px;
    .default-address{
      border-bottom: 1px solid $color4;
      padding-bottom: 20px;
      margin-top: 20px;
      h6{
        @include fonts("Work Sans", $black, 18px, 600, 24px, 0);
        margin-bottom: 12px
      }
      .blocks {
        padding-right: 0;
        margin-bottom: 14px;
        width: 100%;
        @include mobile {
          border-right: 0;
          width: 100%;
        }
        span {
          @include fonts("Work Sans", $black, 18px, 600, 24px, 0);
        }
        .block-title {
          @include flex-between-center;
          label {
            @include fonts("Inter", $color8, 16px, 600, 28px, 0);
          }
        }
        .right-action {
          @include flex-between-center;
          cursor: pointer;
          &:after {
            content: " ";
            @include hw(22px);
            background-image: url(../../../Assets/img/sidebar-emptynav.svg);
            display: inline-block;
          }
        }
        address {
          margin-bottom: 0px;
        }
        .block-content {
          .addr-desc {
            &:not(:last-child) {
              margin-bottom: 12px;
            }
          }
        }
        //&:last-child{
        //  margin-bottom: 0;
        //}
      }
    }
    .address-blocks {
      border-bottom: 1px solid $color4;
      padding-bottom: 20px;
      margin-top: 20px;
      h6{
        @include fonts("Work Sans", $black, 18px, 600, 24px, 0);
        margin-bottom: 12px
      }
      .blocks {
        padding-right: 0;
        margin-bottom: 14px;
        width: 100%;
        @include mobile {
          border-right: 0;
          width: 100%;
        }
        .block-title {
          @include flex-between-center;
          label {
            @include fonts("Inter", $color8, 16px, 600, 28px, 0);
          }
        }
        .right-action {
          @include flex-between-center;
          cursor: pointer;
          &:after {
            content: " ";
            @include hw(22px);
            background-image: url(../../../Assets/img/sidebar-emptynav.svg);
            display: inline-block;
          }
        }
        address {
          margin-bottom: 0px;
        }
        .block-content {
          .addr-desc {
            &:not(:last-child) {
              margin-bottom: 12px;
            }
          }
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .add-new {
      padding-top: 20px;
      // @include mobile {
      //   margin-top: 20px;
      // }
      .add-newwrap {
        display: inline-block;
      }
      .add-new-modal {
        @include flex-start-center;
        @include fonts("Inter", $color1, 16px, 600, 28px, 0);
        cursor: pointer;
        &:before {
          content: " ";
          @include hw(22px);
          background-image: url(../../../Assets/img/new-addr.svg);
          display: inline-block;
          margin-right: 12px;
        }
      }
    }
  }
}
