.order-confirmwrap {
  text-align: center;
  margin: 0 auto;
  padding-top: 64px;
  padding-bottom: 88px;
  @include mobile {
    padding-top: 50px;
    padding-bottom: 0 !important;
  }
  .container {
    // @include mobile {
    //   padding-left: 0 !important;
    //   padding-right: 0 !important;
    // }
  }
  .os-wrapper {
    max-width: 352px;
    margin-left: auto;
    margin-right: auto;
    @include ultra-mobile {
      margin-bottom: 24px;
    }
  }
  .oc-img {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .oc-desc {
    p,
    a {
      padding-bottom: 4px;
      font-weight: 400;
    }
    a {
      @include fonts("Inter", $color1, 16px, 400, 28px, 0);
    }
    .copy-click {
      margin-left: 8px;
      border: 0;
      @include prefix(border-radius, none);
      background-image: url("../../Assets/img/order-copy.svg");
      @include hw(16px);
      background-size: 16px;
      background-color: transparent;
    }
  }
  .action-bar {
    padding-top: 48px;
    @include mobile {
      padding-top: 40px;
    }
    .MuiButton-contained.fill-btn {
      width: 100%;
      @include mobile {
        width: 100%;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .go-back-wrapper {
      text-align: center;
      margin-top: 24px;
    }
  }
}
