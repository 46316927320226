.cmsbanner-wrap.desktop {
  position: relative;
  .cmsbanner-inwrap {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    .container{
      @include above-1400{
        max-width: 1288px;
      }
    }
    // @media (min-width: 1600px) {
    //   left: 11%;
    // }
    // @media (min-width: 1900px) {
    //   left: 17.5%;
    // }
    // @media (max-width: 1400px) {
    //   left: 10%;
    // }
    // @media (max-width: 1400px) {
    //   left: 10%;
    // }
    // @include ultra-desktop-below {
    //   left: 8%;
    // }
    // @include desktop {
    //   left: 5%;
    // }
    // @include tablet {
    //   left: 3%;
    // }
  }
  .cmsbanner-content {
    position: relative;
    display: inline-block;
    img {
      width: 484px;
      @include desktop {
        width: 400px;
      }
      @include tablet {
        width: 320px;
      }
    }
    .cmsshape {
      position: absolute;
      top: 40%;
      left: 40%;
      transform: translate(-40%, -40%);
      @include desktop {
        top: 38%;
        left: 38%;
        transform: translate(-38%, -38%);
      }
      @include tablet {
        top: 40%;
        left: 40%;
        transform: translate(-40%, -40%);
      }
    }
    .cus-breadcrumb {
      padding-top: 0;
      padding-bottom: 10px;
    }
  }
}

.cmsbanner-wrap.mobile {
  position: relative;
  .cmsbanner-img {
    position: relative;
    a{
      display: block;
    }
    .cus-breadcrumb {
      margin: 0;
      position: absolute;
      // top: 16px;
      top: 0;
      left: 28px;
    }
  }
  .cmsbanner-inwrap {
    position: absolute;
    bottom: -37px;
    .cmsbanner-content {
      position: relative;
      img {
        width: auto;
      }
      .abtshape {
        position: absolute;
        top: 50%;
        left: 28px;
        transform: translateY(-50%);
        h1{
          margin: 0;
        }
      }
    }
  }
}

.abtpural-wrap {
  .info-wrap {
    padding: 84px 0 104px 0;
    margin: 0 auto;
    max-width: 600px;
    text-align: left;
    @include ultra-mobile {
      padding: 82px 0 64px 0;
      .container {
        padding: 0 28px;
      }
    }
    h2 {
      @include fonts("Work Sans", #000000, 32px, 600, 36px, 0);
      margin-bottom: 32px;
      @include ultra-mobile {
        margin-bottom: 24px;
      }
    }
    h3{
      @include fonts("Inter", #000000, 24px, 400, 32px, 0);
      margin-bottom: 20px;
      b,strong{
        font-weight: 600;
      }
    }
    img{
      margin-top: 40px;
      margin-bottom: 40px;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto
    }
    p {
      @include fonts("Inter", #000000, 16px, 400, 28px, 0);
      a{
        text-decoration: underline;
        font-weight: 600;
      }
      // &:not(:last-child) {
      //   margin-bottom: 24px;
      //   @include ultra-mobile {
      //     margin-bottom: 16px;
      //   }
      // }
    }
  }
  .filosophy-wrap {
    padding: 72px 0 92px 0;
    background-color: $color2;
    @include desktop-above {
      .container {
        .row {
          margin-left: -40px;
          margin-right: -40px;
          > * {
            padding-left: 40px;
            padding-right: 40px;
          }
        }
      }
    }
    .filo-innerwrap {
      max-width: 1060px;
      margin: 0 auto;
      .filo-points{
        @include ultra-mobile-above{
          &:nth-child(3) ~ *{
            display: none;
          }
        }
      }
    }
    @include tablet-above {
      .container {
        padding-left: 16px;
        padding-right: 16px;
        .row {
          margin-left: -16px;
          margin-right: -16px;
          > * {
            padding-left: 16px;
            padding-right: 16px;
          }
        }
      }
    }
    @include ultra-mobile-above {
      .container {
        .row {
          margin-left: -16px;
          margin-right: -16px;
          > * {
            padding-left: 16px;
            padding-right: 16px;
          }
        }
      }
    }
    @include ultra-mobile {
      padding: 64px 0 80px 0;
      .container {
        padding-left: 28px;
        padding-right: 28px;
      }
      .filo-points {
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
    h1 {
      text-align: center;
      margin-bottom: 72px;
    }
    .for-img {
      margin: 0 auto;
      @include hw(148px);
    }
    .for-desc {
      margin-top: 32px;
      h4 {
        text-transform: uppercase;
        color: $color1;
        margin-bottom: 8px;
      }
    }
  }
}

.pural-mappies-section-2 {
	padding-top: 80px;
	padding-bottom: 80px;
	background: #FDD756;
	@include mobile-above {
		display: none;
	}
	img {
		width: 132px;
		height: auto;
		margin-left: auto;
		margin-right: auto;
	}
	h4 {
		@include fonts("Work Sans", #000000, 32px, 400, 38px, 0.01em);
		padding-left: 20px;
		padding-right: 20px;
		margin-top: -45px;
		text-align: center;
		text-transform: uppercase;
	}
}


.allery-safe-section{
  padding-top: 80px;
  padding-bottom: 104px;
  background-color: #FFF;
  @include mobile-above {
		display: none;
	}
  label{
    @include fonts("Inter", #000000, 16px, 400, 28px, 0);
    margin-bottom: 20px;
  }
  h4{
    @include fonts("Inter", #000000, 24px, 400, 32px, 0);
    margin-bottom: 20px;
  }
  p{
    @include fonts("Inter", #000000, 16px, 400, 28px, 0);
    margin-bottom: 40px;
  }
  .fill-btn{
    padding-left: 4px;
    padding-right: 4px;
		background-color: $color5;
		@include fonts('Work Sans', $color8, 14px, 500, 16px, 0.02em);
		width: 100%;
		&:hover {
			background-color: $color15;
		}
	}
}