.textarea-groups {
	@include prefix(display, flex);
	@include prefix(flex-direction, column);
	textarea {
		width: auto;
		max-width: 600px;
		padding: 0 0 0 6px;
		resize: none;
		border: 0;
		border-bottom: 1px solid $color8 !important;
		border-radius: 0;
		background: #fff;
		@include fonts("Inter", $color8, 16px, normal, 28px, 0);
		&.error{
      border-color: red;
    }
	}
	label {
		padding: 0 0 0 6px;
		font-family: Inter;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		color: #727272;
	}
	span.error{
		@include fonts("Inter", red, 12px, 400, 16px, 0);
		display: block;
		margin-top: 4px;
	}
}