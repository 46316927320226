.something-wrong {
    min-height: 100vh;
    background-color: $color3;
    padding: 50px 0;
    @include flex-center-center;
    .something-wrong-layout {
        text-align: center;
        // max-width: 412px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        // justify-content: space-between;
        justify-content: space-around;
        .wrong-layout-text {
            max-width: 412px;
        }
        img {
            width: 200px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 32px;
            @include mobile {
                width: 65%
            }
        }
        h1 {
            @include fonts("Work Sans", $black, 34px, 600, 32px, 0);
            margin-bottom: 21px;
        }
        p {
            @include fonts("Inter", $black, 16px, 400, 24px, 0);
            margin-bottom: 40px;
        }
        .fill-btn {
            width: 352px;
            @include mobile {
                width: 100%;
            }
        }
    }
}