.address-steps {
  padding: 40px 28px;
  border: 1px solid $color4;
  margin-bottom: 16px;
  @include tablet {
    padding: 32px 20px 24px 20px;
  }
  .addr-innerwrap {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $color4;
    @include prefix(display, flex);
    @include prefix(align-items, start);
    &.no-border {
      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
    .addr-content {
      padding-left: 0px;
      width: 100%;
      position: relative;
      .edit-icons{
        position: absolute;
        top: -5px;
        right: 0;
        @include hw(32px);
        cursor: pointer;
      }
      h5 {
        @include fonts("Inter", $color8, 14px, 700, 24px, 0);
      }
      .address-shipping,
      .address-billing {
        @include fonts("Inter", $color8, 14px, 400, 24px, 0);
        address {
          margin-bottom: 0;
          @include ultra-mobile {
            display: block;
            display: -webkit-box;
            max-width: 100%;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .address-billing {
        margin-bottom: 24px;
        position: relative;
      }

      .checkout-address-box{
        padding-left: 0px;
        width: 100%;
        margin-bottom: 8px;
        position: relative;
        @include flex-start-start;
        .rd{
          & + .address-shipping {
            padding-left: 12px;
            width: calc(100% - 23px);
          }
        }  
        & + .address-billing{
          padding-left: 35px;
        }
      }
    }
    .rd{
      & + .addr-content{
        padding-left: 12px;
        width: calc(100% - 23px);
      }
    }
  }
  .add-new {
    .add-newwrap {
      display: inline-block;
    }
    .add-new-modal {
      @include flex-start-center;
      @include fonts("Inter", $color1, 16px, 600, 28px, 0);
      cursor: pointer;
      &:before {
        content: " ";
        @include hw(22px);
        background-image: url(../../../Assets/img/new-addr.svg);
        display: inline-block;
        margin-right: 12px;
      }
    }
  }
}
